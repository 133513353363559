<template>
    <div v-show="!loading">
        <formulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="peopleEditForm"
            @submit="handleSubmit"
        >
            <b-card class="mt-2">
                <div class="mb-2">
                    <span class="section-header">
                        <feather-icon icon="UserIcon" size="14"></feather-icon>
                        {{ $t("modules.people.profile") }}
                    </span>
                </div>

                <b-row class="mt-2">
                    <b-col cols="12" md="6">
                        <formulateInput
                            :label="$t('common.crud.name')"
                            name="name"
                            :placeholder="$t('common.crud.name')"
                            type="text"
                            validation="required|max:200,length"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('common.status')"
                            name="status"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                statusOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.status = $event"
                        />
                    </b-col>

                    <b-col
                        v-if="isStatusInactive(formData.status)"
                        cols="12"
                        md="3"
                    >
                        <formulateInput
                            :label="$t('modules.people.inactivationReason')"
                            :placeholder="$t('common.crud.select')"
                            name="inactivationReason"
                            type="vue-select"
                            validation="optional|max:32,length"
                            :options="
                                inactivationReasonOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.inactivationReason = $event"
                        />
                    </b-col>
                </b-row>

                <b-row class="mt-1">
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.salutation')"
                            name="salutation"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                salutationsOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.salutation = $event"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.gender')"
                            name="gender"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                gendersOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.gender = $event"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.documentNumber')"
                            name="documentNumber"
                            :placeholder="$t('modules.people.documentNumber')"
                            type="text"
                            validation="optional|cpf"
                            :validationMessages="{
                                cpf: $t(
                                    'common.crud.errors.invalidDocumentNumber'
                                ),
                            }"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.identityCard')"
                            name="identityCard"
                            :placeholder="$t('modules.people.identityCard')"
                            type="text"
                            validation="optional|max:200,length"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" md="3">
                        <formulate-input
                            v-model="formData.birthDate"
                            :label="$t('modules.people.birthdate')"
                            :placeholder="$t('customFormats.date')"
                            name="birthdate"
                            type="custom-date"
                            validation="optional|date:YYYY-MM-DD"
                            @update:date="formData.birthDate = $event"
                        >
                        </formulate-input>
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.scholarity')"
                            name="scholarity"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                scholaritiesOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.scholarity = $event"
                        />
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="mt-1">
                <div class="mb-2">
                    <span class="section-header">
                        <feather-icon icon="InfoIcon" size="14"></feather-icon>
                        {{ $t("modules.people.contactInformation") }}
                    </span>
                </div>

                <b-row>
                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.origin')"
                            name="origin"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional|max:32,length"
                            :options="
                                originsOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.origin = $event"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.organizationContact')"
                            name="organizationContact"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                organizationsContactsOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="
                                formData.organizationContact = $event
                            "
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.correspondence')"
                            name="correspondence"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                correspondencesOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.correspondence = $event"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <formulateInput
                            :label="$t('modules.people.newsLetter')"
                            name="newsletter"
                            :placeholder="$t('common.crud.select')"
                            type="vue-select"
                            validation="optional"
                            :options="
                                newslettersOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                            @update:input="formData.newsletter = $event"
                        />
                    </b-col>
                </b-row>
            </b-card>

            <b-card class="mt-1">
                <div class="mb-2">
                    <span class="section-header">
                        <feather-icon
                            icon="BriefcaseIcon"
                            size="14"
                        ></feather-icon>
                        {{ $t("modules.people.contactInformationAtCompany") }}
                    </span>
                </div>

                <b-row>
                    <b-col cols="12" md="6">
                        <select-company
                            :label="$tc('modules.companies.description', 1)"
                            :placeholder="$t('common.crud.select')"
                            name="companyId"
                            :options="companies"
                            validation="optional"
                            @update:input="formData.companyId = $event"
                            @update:options="companies = $event"
                        >
                        </select-company>
                    </b-col>

                    <b-col cols="12" md="6">
                        <FormulateInput
                            :label="$t('modules.people.jobDescription')"
                            name="jobDescription"
                            :placeholder="$t('modules.people.jobDescription')"
                            type="text"
                            validation="optional|max:255,length"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <FormulateInput
                            :label="$t('modules.people.primaryContact')"
                            name="isPrimaryContact"
                            :placeholder="$t('common.crud.select')"
                            type="select"
                            :options="{
                                true: $t('common.yes'),
                                false: $t('common.no'),
                            }"
                        />
                    </b-col>

                    <b-col cols="12" md="3">
                        <FormulateInput
                            :label="$t('modules.people.extension')"
                            name="extension"
                            :placeholder="$t('modules.people.extension')"
                            type="text"
                            validation="optional|extension"
                            :validationMessages="{
                                extension: $t(
                                    'common.crud.errors.invalidExtension'
                                ),
                            }"
                        />
                    </b-col>
                    <b-col cols="12" md="6">
                        <FormulateInput
                            :label="$t('modules.people.assistent')"
                            name="assistent"
                            :placeholder="$t('modules.people.assistent')"
                            type="text"
                            validation="max:255,length"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <FormulateInput
                            :label="$t('modules.people.assistentPhone')"
                            name="assistentPhone"
                            :placeholder="$t('modules.people.assistentPhone')"
                            type="text"
                            validation="optional|phone"
                            :validationMessages="{
                                phone: $t('common.crud.errors.invalidPhone'),
                            }"
                        />
                    </b-col>
                    <b-col cols="12" md="3">
                        <FormulateInput
                            :label="$t('modules.people.assistentExtension')"
                            name="assistentExtension"
                            :placeholder="
                                $t('modules.people.assistentExtension')
                            "
                            type="text"
                            validation="optional|extension"
                            :validationMessages="{
                                extension: $t(
                                    'common.crud.errors.invalidExtension'
                                ),
                            }"
                        />
                    </b-col>
                    <b-col cols="12" md="6">
                        <FormulateInput
                            :label="$t('modules.people.assistentEmail')"
                            name="assistentEmail"
                            :placeholder="$t('modules.people.assistentEmail')"
                            type="text"
                            validation="optional|max:255,length|email"
                        />
                    </b-col>
                </b-row>
            </b-card>

            <b-row>
                <b-col class="d-flex justify-content-center">
                    <formulateErrors />
                </b-col>
            </b-row>

            <b-row class="justify-content-center align-self-center mt-2 ml-0">
                <formulateInput
                    :disabled="hasErrors || isLoading"
                    :label="
                        isLoading
                            ? $t('common.crud.updating')
                            : $t('common.crud.update')
                    "
                    inputClass="btn btn-primary"
                    type="submit"
                    class="pr-2"
                />
                <formulateInput
                    :label="$t('common.crud.close')"
                    inputClass="btn btn-danger"
                    type="button"
                    class="pl-2"
                    @click="$emit('closeEditForm')"
                />
            </b-row>
        </formulateForm>
    </div>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue"
import Api from "@/services/api/index"
import _ from "lodash"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import SelectCompany from "@/views/components/utilis-spa/vue-select-async/SelectCompany.vue"

export default {
    components: {
        BRow,
        BCol,
        BCard,
        SelectCompany,
    },
    props: {
        personData: {
            required: true,
            type: Object,
        },
        statusOptions: {
            required: true,
            type: Array,
        },
        originsOptions: {
            required: true,
            type: Array,
        },
        gendersOptions: {
            required: true,
            type: Array,
        },
        salutationsOptions: {
            required: true,
            type: Array,
        },
        correspondencesOptions: {
            required: true,
            type: Array,
        },
        scholaritiesOptions: {
            required: true,
            type: Array,
        },
        organizationsContactsOptions: {
            required: true,
            type: Array,
        },
        newslettersOptions: {
            required: true,
            type: Array,
        },
        inactivationReasonOptions: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            formData: {
                name: "",
                socialName: "",
                documentNumber: "",
                birthDate: "",
                statusReason: "",
                identityCard: "",
                correspondence: "",
                inactivationReason: "",
                salutation: "",
                scholarity: "",
                newsletter: "",
                organizationContact: "",
                relationships: [],
                classifications: [],
                phones: [],
                emails: [],
                addresses: [],
                status: null,
                origin: null,
                gender: null,
                jobDescription: "",
                extension: "",
                isPrimaryContact: "",
                assistent: "",
                assistentPhone: "",
                assistentExtension: "",
                assistentEmail: "",
                personId: "",
                companyId: "",
            },
            classifications: [],
            relationships: [],
            status: [],
            origins: [],
            genders: [],
            users: [],
            people: [],
            updatedPerson: null,
            loading: true,
            companies: [],
        }
    },

    mounted() {
        this.setInitialValues(this.personData)
    },

    methods: {
        setInitialValues(data) {
            // Setting up initial values
            this.updatedPerson = data
            this.formData.name = data.name
            this.formData.socialName = data.socialName
            this.formData.documentNumber = data.documentNumber
            this.formData.birthDate = data.birthDate
            this.formData.identityCard = data.identityCard
            this.formData.statusReason = data.statusReason
            this.formData.relationships =
                (_.get(data, "relations") &&
                    data.relations.map(el => el.id.toString())) ||
                []
            this.formData.classifications =
                (_.get(data, "classifications") &&
                    data.classifications.map(el => el.id.toString())) ||
                []
            this.formData.phones = (_.get(data, "phones") && data.phones) || []
            this.formData.emails = (_.get(data, "emails") && data.emails) || []
            this.formData.addresses =
                (_.get(data, "addresses") && data.addresses) || []
            this.formData.status =
                (_.get(data, "status.id") && data.status.id.toString()) || null
            this.formData.origin = _.get(data, "origin.id") || null
            this.formData.gender = _.get(data, "gender.id") || null
            this.formData.correspondence =
                _.get(data, "correspondence.id") || null
            this.formData.inactivationReason =
                _.get(data, "inactivationReason.id") || null
            this.formData.salutation = _.get(data, "salutation.id") || null
            this.formData.scholarity = _.get(data, "scholarity.id") || null
            this.formData.newsletter = _.get(data, "newsletter.id") || null
            this.formData.organizationContact =
                _.get(data, "organizationContact.id") || null
            this.formData.inactivationReason =
                _.get(data, "inactivationReason.id") || null

            // COMPANIES-PEOPLE DATA
            this.companies = data.companies.map(el => ({
                value: el.id,
                label: el.name,
            }))

            this.formData.jobDescription = _.get(
                data,
                "company.jobDescription",
                ""
            )
            this.formData.extension = _.get(data, "company.extension", "")
            this.formData.isPrimaryContact = _.get(
                data,
                "company.isPrimaryContact"
            )
                ? data.company.isPrimaryContact.toString()
                : "false"
            this.formData.assistent = _.get(data, "company.assistent", "")
            this.formData.assistentPhone = _.get(
                data,
                "company.assistentPhone",
                ""
            )
            this.formData.assistentExtension = _.get(
                data,
                "company.assistentExtension",
                ""
            )
            this.formData.assistentEmail = _.get(
                data,
                "company.assistentEmail",
                ""
            )
            this.formData.companyId = _.get(data, "company.companyId")
                ? String(_.get(data, "company.companyId"))
                : null
            this.loading = false
        },
        isStatusInactive(statusId) {
            if (statusId === "2") return true

            this.formData.inactivationReason = ""
            return false
        },

        async handleSubmit() {
            try {
                const personRequest = {
                    id: this.personData.id,
                    name: this.formData.name,
                    socialName: this.formData.socialName,
                    documentNumber: this.formData.documentNumber,
                    identityCard: this.formData.identityCard,
                    birthDate: this.formData.birthDate,
                    origin: this.formData.origin
                        ? { id: this.formData.origin }
                        : null,
                    gender: this.formData.gender
                        ? { id: this.formData.gender }
                        : null,
                    status: this.formData.status
                        ? { id: this.formData.status }
                        : null,
                    correspondence: this.formData.correspondence
                        ? {
                              type: "correspondences",
                              id: this.formData.correspondence,
                          }
                        : null,
                    inactivationReason: this.formData.inactivationReason
                        ? {
                              type: "inactivation-reasons",
                              id: this.formData.inactivationReason,
                          }
                        : null,
                    salutation: this.formData.salutation
                        ? { type: "salutations", id: this.formData.salutation }
                        : null,
                    scholarity: this.formData.scholarity
                        ? { type: "scholarities", id: this.formData.scholarity }
                        : null,
                    newsletter: this.formData.newsletter
                        ? { type: "newsletters", id: this.formData.newsletter }
                        : null,
                    organizationContact: this.formData.organizationContact
                        ? {
                              type: "organizations-contacts",
                              id: this.formData.organizationContact,
                          }
                        : null,
                    classifications:
                        this.formData.classifications.length > 0
                            ? this.formData.classifications.map(el => ({
                                  id: el,
                              }))
                            : [],
                    relations:
                        this.formData.relationships.length > 0
                            ? this.formData.relationships.map(el => ({
                                  id: el,
                              }))
                            : [],
                }

                await Api.update("people", { ...personRequest })

                // COMPANY-PERSON
                if (this.personData.company) {
                    await Api.update("companies-people", {
                        id: this.personData.company.id,
                        companyId: parseInt(this.formData.companyId, 10),
                        jobDescription: this.formData.jobDescription,
                        extension: this.formData.extension,
                        isPrimaryContact:
                            this.formData.isPrimaryContact === "true",
                        assistent: this.formData.assistent,
                        assistentPhone: this.formData.assistentPhone,
                        assistentExtension: this.formData.assistentExtension,
                        assistentEmail: this.formData.assistentEmail,
                    })
                } else {
                    await Api.create("companies-people", {
                        companyId: parseInt(this.formData.companyId, 10),
                        personId: parseInt(this.personData.id, 10),
                        jobDescription: this.formData.jobDescription,
                        extension: this.formData.extension,
                        isPrimaryContact:
                            this.formData.isPrimaryContact === "true",
                        assistent: this.formData.assistent,
                        assistentPhone: this.formData.assistentPhone,
                        assistentExtension: this.formData.assistentExtension,
                        assistentEmail: this.formData.assistentEmail,
                    })
                }

                this.$toast(toastMessages.getSuccessMessage())

                this.updatePerson()
                this.$formulate.resetValidation("peopleEditForm")
            } catch (e) {
                this.$formulate.handle(e, "peopleEditForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        updatePerson() {
            this.updatedPerson.name = this.formData.name
            this.updatedPerson.socialName = this.formData.socialName
            this.updatedPerson.documentNumber = this.formData.documentNumber
            this.updatedPerson.identityCard = this.formData.identityCard
            this.updatedPerson.birthDate = this.formData.birthDate
            const [gender] = this.gendersOptions.filter(
                option => option.id === this.formData.gender,
                this.formData.gender
            )
            const [origin] = this.originsOptions.filter(
                option => option.id === this.formData.origin
            )
            const [status] = this.statusOptions.filter(
                option => option.id === this.formData.status
            )
            const [correspondence] = this.correspondencesOptions.filter(
                option => option.id === this.formData.correspondence
            )
            const [salutation] = this.salutationsOptions.filter(
                option => option.id === this.formData.salutation
            )
            const [scholarity] = this.scholaritiesOptions.filter(
                option => option.id === this.formData.scholarity
            )
            const [newsletter] = this.newslettersOptions.filter(
                option => option.id === this.formData.newsletter
            )
            const [
                organizationContact,
            ] = this.organizationsContactsOptions.filter(
                option => option.id === this.formData.organizationContact
            )
            this.updatedPerson.emails = this.formData.emails
            this.updatedPerson.addresses = this.formData.addresses
            this.updatedPerson.phones = this.formData.phones
            this.updatedPerson.company.jobDescription = this.formData.jobDescription
            this.updatedPerson.company.extension = this.formData.extension
            this.updatedPerson.company.isPrimaryContact =
                this.formData.isPrimaryContact === "true"
            this.updatedPerson.company.assistent = this.formData.assistent
            this.updatedPerson.company.assistentPhone = this.formData.assistentPhone
            this.updatedPerson.company.assistentExtension = this.formData.assistentExtension
            this.updatedPerson.company.assistentEmail = this.formData.assistentEmail
            this.updatedPerson.company.companyId = this.formData.companyId
            this.updatedPerson.gender = gender
            this.updatedPerson.origin = origin
            this.updatedPerson.status = status
            this.updatedPerson.correspondence = correspondence
            this.updatedPerson.salutation = salutation
            this.updatedPerson.scholarity = scholarity
            this.updatedPerson.newsletter = newsletter
            this.updatedPerson.organizationContact = organizationContact

            this.$emit("update:person", this.updatedPerson)
        },
    },
}
</script>

<style scoped lang="scss">
.inputSize {
    width: 90%;
}

.section-header {
    color: #babfc7;
}

.a-link {
    color: rgb(81, 155, 214) !important;
}
</style>
