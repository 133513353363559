<template>
    <FormulateForm
        ref="formRef"
        v-model="formData"
        name="addressForm"
        @submit="dispatchRequest"
    >
        <b-overlay :show="loadingAddressesRepository" rounded="sm">
            <b-row class="mt-1">
                <b-col cols="12" lg="6">
                    <FormulateInput
                        name="postalCode"
                        :disabled="disableForm"
                        :label="$t('common.address.postalCode')"
                        :placeholder="$t('common.address.postalCode')"
                        type="text"
                        :validation="[
                            addressValidation,
                            'max:8,length',
                            'min:8,length',
                        ]"
                        @validation="getAddress"
                    >
                        <template #help>
                            <span>{{
                                $t("common.address.postalCodeQuestion")
                            }}</span>
                            <a
                                class="under-blue-text mr-2"
                                href="https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php"
                                target="_blank"
                            >
                                {{ $t("common.crud.searchHere") }}
                            </a>
                        </template>
                    </FormulateInput>
                </b-col>

                <b-col cols="12" lg="6">
                    <FormulateInput
                        :disabled="disableForm"
                        :label="$tc('common.address.description', 1)"
                        :placeholder="$tc('common.address.description', 1)"
                        name="description"
                        type="text"
                        validation="optional|max:250,length"
                    />
                </b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col cols="12" lg="6">
                    <FormulateInput
                        :disabled="disableForm"
                        :label="$t('common.address.neighborhood')"
                        :placeholder="$t('common.address.neighborhood')"
                        name="neighborhood"
                        type="text"
                        validation="optional|max:100,length"
                    />
                </b-col>

                <b-col cols="8" md="4">
                    <FormulateInput
                        :disabled="disableForm"
                        :label="$t('common.address.complement')"
                        :placeholder="$t('common.address.complement')"
                        name="complement"
                        type="text"
                        validation="optional|max:100,length"
                    />
                </b-col>

                <b-col cols="4" md="2">
                    <FormulateInput
                        :disabled="disableForm"
                        :label="$t('common.address.number')"
                        :placeholder="$t('common.address.number')"
                        name="number"
                        type="text"
                        :validation="[addressValidation, 'max:10,length']"
                    />
                </b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col cols="4" md="3">
                    <FormulateInput
                        :disabled="disableForm"
                        :label="$t('common.address.state')"
                        :placeholder="$t('common.address.state')"
                        name="state"
                        type="select"
                        :options="stateOptions"
                        validation="optional"
                    />
                </b-col>

                <b-col cols="8" md="4">
                    <FormulateInput
                        :disabled="disableForm"
                        :label="$t('common.address.city')"
                        :placeholder="$t('common.address.city')"
                        name="city"
                        type="text"
                        validation="optional|max:250,length"
                    />
                </b-col>

                <b-col cols="12" md="5">
                    <FormulateInput
                        :disabled="disableForm"
                        :label="$t('common.address.reference')"
                        :placeholder="$t('common.address.reference')"
                        name="reference"
                        type="text"
                        validation="optional|max:250,length"
                    />
                </b-col>
            </b-row>

            <b-row>
                <b-col class="d-flex justify-content-center">
                    <formulateErrors />
                </b-col>
            </b-row>
        </b-overlay>
    </FormulateForm>
</template>

<script>
import { BRow, BCol, BOverlay } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BRow,
        BCol,
        BOverlay,
    },
    props: {
        resourceId: {
            required: true,
            type: String,
            default: "",
        },
        addressEdit: {
            required: false,
            type: Object,
            default: null,
        },
        resourceClass: {
            required: false,
            type: String,
            default: "",
        },
        disableForm: {
            required: false,
            type: Boolean,
            default: false,
        },
        disableSearch: {
            required: false,
            type: Boolean,
            default: false,
        },
        addressValidation: {
            required: false,
            default: "required",
            type: String,
        },
    },
    data() {
        return {
            formData: {
                postalCode: "",
                isMainAddress: false,
                reference: "",
                number: "",
                complement: "",
                description: "",
                neighborhood: "",
            },
            loadingAddressesRepository: false,

            stateOptions: [
                "AC",
                "AL",
                "AP",
                "AM",
                "BA",
                "CE",
                "ES",
                "GO",
                "MA",
                "MT",
                "MS",
                "MG",
                "PA",
                "PB",
                "PR",
                "PE",
                "PI",
                "RJ",
                "RN",
                "RS",
                "RO",
                "RR",
                "SC",
                "SP",
                "SE",
                "TO",
                "DF",
            ],
        }
    },
    mounted() {
        this.setAttributesDefaultValues()
    },
    methods: {
        setAttributesDefaultValues() {
            if (this.addressEdit) {
                this.formData.postalCode = this.addressEdit.postalCode
                this.formData.isMainAddress = this.addressEdit.isMainAddress
                this.formData.reference = this.addressEdit.reference
                this.formData.number = this.addressEdit.number
                this.formData.complement = this.addressEdit.complement || ""
                this.formData.description = this.addressEdit.description || ""
                this.formData.street = this.addressEdit.street || ""
                this.formData.state = this.addressEdit.state || ""
                this.formData.city = this.addressEdit.city || ""
                this.formData.neighborhood =
                    this.addressEdit.neighborhood || ""
            } else {
                this.formData.postalCode = ""
                this.formData.isMainAddress = false
                this.formData.reference = ""
                this.formData.number = ""
                this.formData.complement = ""
                this.formData.description = ""
                this.formData.street = ""
                this.formData.state = ""
                this.formData.neighborhood = ""
                this.formData.city = ""
            }
        },
        async getAddress(event) {
            if (event.hasErrors || this.disableSearch) return

            this.loadingAddressesRepository = true
            try {
                const response = await Api.findAll("addresses-repository", {
                    filter: {
                        postalCode: this.formData.postalCode,
                    },
                })

                this.formData.description = response.data[0].street
                this.formData.city = response.data[0].locality
                this.formData.state = response.data[0].state
                this.formData.neighborhood = response.data[0].neighborhood
            } finally {
                this.loadingAddressesRepository = false
            }
        },
        // eslint-disable-next-line consistent-return
        async dispatchRequest() {
            try {
                if (this.addressEdit) {
                    if (this.formData.postalCode === "")
                        return Api.destroy("addresses", this.addressEdit.id)

                    return Api.update("addresses", {
                        id: this.addressEdit.id.toString(),
                        postalCode: this.formData.postalCode,
                        isMainAddress: this.formData.isMainAddress,
                        reference: this.formData.reference,
                        number: this.formData.number,
                        complement: this.formData.complement,
                        description: this.formData.description,
                        modelId: parseFloat(this.resourceId),
                        modelType: this.resourceClass,
                    })
                }

                if (this.formData.postalCode === "") return true

                return Api.create("addresses", {
                    postalCode: this.formData.postalCode,
                    isMainAddress: this.formData.isMainAddress,
                    reference: this.formData.reference,
                    number: this.formData.number,
                    complement: this.formData.complement,
                    description: this.formData.description,
                    modelId: parseFloat(this.resourceId),
                    modelType: this.resourceClass,
                })
            } catch (e) {
                this.$formulate.handle(e, "addressForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style scoped></style>
