import { render, staticRenderFns } from "./CompanyCard.vue?vue&type=template&id=284032a8&scoped=true&"
import script from "./CompanyCard.vue?vue&type=script&lang=js&"
export * from "./CompanyCard.vue?vue&type=script&lang=js&"
import style0 from "./CompanyCard.vue?vue&type=style&index=0&id=284032a8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284032a8",
  null
  
)

export default component.exports