<template>
    <b-modal
        v-if="$can('create', 'material-donations')"
        id="create-material-donations-modal"
        v-model="active"
        centered
        hideFooter
        size="xl"
        headerClass="modal-custom-header"
        @show="setAttributesDefaultValues"
    >
        <template #modal-title>
            <span class="text-white">
                {{ $tc("modules.materialDonations.description", 1) }}
            </span>
        </template>

        <template #default>
            <div>
                <formulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="materialDonationCreate"
                >
                    <b-row class="mt-1">
                        <b-col cols="12" xl="3">
                            <select-action
                                name="action"
                                :placeholder="
                                    $t('common.crud.select') +
                                    ' ' +
                                    $tc(
                                        'modules.actions.description',
                                        1
                                    ).toLowerCase()
                                "
                                :label="$tc('modules.actions.description', 1)"
                                validation="required"
                                :options="actionsOptions"
                                @update:options="actionsOptions = $event"
                                @update:input="formData.action = $event"
                            >
                            </select-action>
                        </b-col>
                        <b-col cols="12" xl="2">
                            <FormulateInput
                                name="isCompany"
                                type="checkbox"
                                class="ml-4 mt-2"
                                :disabled="!!donationContact"
                                :label="
                                    $t('modules.materialDonations.isCompany')
                                "
                            />
                        </b-col>
                        <b-col cols="12" xl="3">
                            <select-person
                                v-if="!formData.isCompany"
                                name="contact"
                                :placeholder="
                                    $t('common.crud.select') +
                                    ' ' +
                                    $tc(
                                        'modules.people.description',
                                        1
                                    ).toLowerCase()
                                "
                                :label="$t('modules.materialDonations.donor')"
                                validation="required"
                                :options="donorOptions"
                                :setDisabled="!!donationContact"
                                @update:options="donorOptions = $event"
                                @update:input="formData.contact = $event"
                            >
                            </select-person>

                            <select-company
                                v-if="formData.isCompany"
                                :label="$t('modules.materialDonations.donor')"
                                :placeholder="
                                    $t('common.crud.select') +
                                    ' ' +
                                    $tc(
                                        'modules.companies.description',
                                        1
                                    ).toLowerCase()
                                "
                                name="contact"
                                validation="required"
                                :options="donorOptions"
                                :setDisabled="!!donationContact"
                                @update:options="donorOptions = $event"
                                @update:input="formData.contact = $event"
                            >
                            </select-company>
                        </b-col>
                    </b-row>

                    <b-row>
                        <material-donation-products-list
                            ref="productsForm"
                            :productsList="formData.products"
                            :productsCategories="productCategoriesOptions"
                            :unitsOfMeasurement="unitsOfMeasurementOptions"
                            :deliveryLocations="deliveryLocationOptions"
                            @updateProductsList="updateProducts($event)"
                        >
                        </material-donation-products-list>
                    </b-row>

                    <b-row class="mb-1">
                        <div class="subsection-title">
                            <span>
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.deliveryInformation"
                                        )
                                    }}
                                </strong>
                            </span>
                        </div>
                    </b-row>

                    <b-row>
                        <b-col cols="12" xl="4">
                            <formulateInput
                                name="deliveryLocation"
                                :label="
                                    $t(
                                        'modules.materialDonations.deliveryLocation'
                                    )
                                "
                                :placeholder="
                                    $t('common.crud.select') +
                                    ' ' +
                                    $t(
                                        'modules.materialDonations.deliveryLocation'
                                    ).toLowerCase()
                                "
                                type="vue-select"
                                validation="optional"
                                :options="deliveryLocationOptions"
                                :reduceFunc="(x) => x.id"
                                recudeLabel="description"
                                @update:input="
                                    formData.deliveryLocation = $event
                                "
                            />
                        </b-col>
                        <b-col cols="12" xl="2">
                            <formulate-input
                                :disabled="!checkForDelivery"
                                name="schedulingDate"
                                :label="
                                    $t(
                                        'modules.materialDonations.schedulingDate'
                                    )
                                "
                                :placeholder="
                                    $t(
                                        'modules.materialDonations.schedulingDate'
                                    )
                                "
                                type="custom-date"
                                validation="optional|date:YYYY-MM-DD"
                                @update:date="formData.schedulingDate = $event"
                            >
                            </formulate-input>
                        </b-col>
                        <b-col cols="12" xl="2">
                            <formulateInput
                                :disabled="!checkForDelivery"
                                name="schedulingTime"
                                :label="$t('modules.materialDonations.time')"
                                placeholder="00:00"
                                type="text"
                                maxlength="5"
                                :validation="[
                                    [
                                        'matches',
                                        '/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/',
                                    ],
                                    ['optional'],
                                ]"
                                @input="timeMask(formData.schedulingTime)"
                            />
                        </b-col>
                        <b-col cols="12" xl="2">
                            <formulate-input
                                name="withdrawalDate"
                                :label="
                                    $t(
                                        'modules.materialDonations.withdrawalDate'
                                    )
                                "
                                :placeholder="
                                    $t(
                                        'modules.materialDonations.withdrawalDate'
                                    )
                                "
                                type="custom-date"
                                validation="optional|date:YYYY-MM-DD"
                                @update:date="formData.withdrawalDate = $event"
                            />
                        </b-col>
                        <b-col cols="12" xl="2">
                            <formulateInput
                                name="withdrawalTime"
                                :label="$t('modules.materialDonations.time')"
                                placeholder="00:00"
                                type="text"
                                maxlength="5"
                                :validation="[
                                    [
                                        'matches',
                                        '/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/',
                                    ],
                                    ['optional'],
                                ]"
                                @input="timeMask(formData.withdrawalTime)"
                            />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <formulateInput
                                name="observation"
                                :label="
                                    $t('modules.materialDonations.observation')
                                "
                                :placeholder="
                                    $t('modules.materialDonations.observation')
                                "
                                type="text"
                                validation="optional|max:255,length"
                            />
                        </b-col>
                    </b-row>

                    <b-row v-if="checkForWithdrawOperation" class="mb-1 mt-2">
                        <div class="subsection-title">
                            <span class="text-bold">
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.withdrawalInformation"
                                        )
                                    }}
                                </strong>
                            </span>
                        </div>
                    </b-row>

                    <b-row v-if="checkForWithdrawOperation">
                        <b-col cols="12" xl="3" class="mt-1">
                            <formulateInput
                                :disabled="!checkForDelivery"
                                name="withdrawalAddress"
                                :label="
                                    $t('modules.materialDonations.donorAddress')
                                "
                                :placeholder="
                                    $t('common.crud.select') +
                                    ' ' +
                                    $t(
                                        'modules.materialDonations.donorAddress'
                                    ).toLowerCase()
                                "
                                type="vue-select"
                                validation="optional"
                                :options="donorAddressesOptions"
                                @update:input="
                                    formData.withdrawalAddress = $event
                                "
                            />
                            <div class="mt-3">
                                <feather-icon icon="MapPinIcon" size="20" />
                                <span class="text-bold">
                                    {{
                                        $t(
                                            "modules.materialDonations.addressName"
                                        )
                                    }}
                                </span>
                                <div class="mt-2">
                                    <span>
                                        {{
                                            (
                                                donorAddressesOptions.find(
                                                    (el) =>
                                                        el.id ===
                                                        formData.withdrawalAddress
                                                ) || {}
                                            ).completeAddress || ""
                                        }}
                                    </span>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" xl="9">
                            <attach-address-horizontal
                                ref="addressForm"
                                :resourceId="
                                    materialDonationCreated
                                        ? materialDonationCreated.id
                                        : ''
                                "
                                resourceClass="MATERIAL_DONATION"
                                :disableForm="!checkForDelivery"
                                addressValidation="optional"
                            >
                            </attach-address-horizontal>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <formulateErrors />
                        </b-col>
                    </b-row>

                    <b-row
                        class="justify-content-center align-self-center mt-2 ml-0"
                    >
                        <formulateInput
                            :disabled="hasErrors || isLoading"
                            :label="
                                isLoading
                                    ? $t('common.crud.creating')
                                    : $t('common.crud.create')
                            "
                            inputClass="btn btn-primary"
                            type="button"
                            class="pr-2"
                            @click="handleSubmit"
                        />
                        <formulateInput
                            :label="$t('common.crud.close')"
                            inputClass="btn btn-danger"
                            type="button"
                            class="pl-2"
                            @click="hide"
                        />
                    </b-row>
                </formulateForm>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BRow, BCol, BModal } from "bootstrap-vue"
import Api from "@/services/api"
import SelectAction from "@/views/components/utilis-spa/vue-select-async/SelectAction.vue"
import SelectPerson from "@/views/components/utilis-spa/vue-select-async/SelectUser.vue"
import AttachAddressHorizontal from "@/views/components/utilis-spa/address/AttachAddressHorizontal.vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import SelectCompany from "@/views/components/utilis-spa/vue-select-async/SelectCompany.vue"
import MaterialDonationProductsList from "./MaterialDonationProductsList.vue"

export default {
    components: {
        SelectCompany,
        BModal,
        MaterialDonationProductsList,
        AttachAddressHorizontal,
        BRow,
        BCol,
        SelectAction,
        SelectPerson,
    },
    props: {
        donationContactIsCompany: {
            required: false,
            type: Boolean,
            default: false,
        },
        donationContact: {
            required: false,
            type: Object,
            default: () => {},
        },
    },

    data() {
        return {
            formData: {
                action: "",
                contact: "",
                isCompany: "",
                deliveryLocation: "",
                schedulingDate: "",
                schedulingTime: "",
                withdrawalDate: "",
                observation: "",
                withdrawalTime: "",
                withdrawalAddress: "",
                products: [],
            },
            actionsOptions: [],
            donorOptions: [],
            deliveryLocationOptions: [],
            productCategoriesOptions: [],
            unitsOfMeasurementOptions: [],
            materialDonationStatusOptions: [],
            addressClassificationsOptions: [],
            donorAddressesOptions: [],
            productsColumns: [],
            active: false,
            materialDonationCreated: null,
        }
    },
    computed: {
        schedulingTime() {
            return this.formData.schedulingTime
        },
        withdrawalTime() {
            return this.formData.withdrawalTime
        },
        deliveryLocation() {
            return this.formData.deliveryLocation
        },
        withdrawalAddress() {
            return this.formData.withdrawalAddress
        },
        checkForDelivery() {
            return this.formData.deliveryLocation === "1"
        },
        contact() {
            return this.formData.contact
        },
    },
    watch: {
        deliveryLocation(val) {
            setTimeout(() => {
                if (
                    this.disableAutoFill ||
                    !this.$refs.addressForm ||
                    this.checkForDelivery
                )
                    return
                const deliveryLocation = this.deliveryLocationOptions.filter(
                    el => el.id === val
                )[0]
                if (!deliveryLocation) {
                    return
                }
                if (deliveryLocation.id === "1") {
                    this.formData.schedulingDate = ""
                    this.formData.schedulingTime = ""
                }
                this.$refs.addressForm.$data.formData.description =
                    deliveryLocation.description
                this.$refs.addressForm.$data.formData.postalCode =
                    deliveryLocation.postalCode
                this.$refs.addressForm.$data.formData.neighborhood =
                    deliveryLocation.neighborhood
                this.$refs.addressForm.$data.formData.complement =
                    deliveryLocation.complement
                this.$refs.addressForm.$data.formData.number =
                    deliveryLocation.number
                this.$refs.addressForm.$data.formData.state =
                    deliveryLocation.state
                this.$refs.addressForm.$data.formData.city =
                    deliveryLocation.locality
                this.$refs.addressForm.$data.formData.reference =
                    deliveryLocation.reference
                this.$refs.addressForm.$data.formData.isMainAddress =
                    deliveryLocation.isMainAddress
            }, 300)
        },
        withdrawalAddress(newVal) {
            if (!newVal) return
            setTimeout(() => {
                if (!this.$refs.addressForm) return
                const withdrawalAddress = this.donorAddressesOptions.filter(
                    el => el.id === newVal
                )[0]
                if (!withdrawalAddress) {
                    return
                }
                this.$refs.addressForm.$data.formData.description =
                    withdrawalAddress.description
                this.$refs.addressForm.$data.formData.postalCode =
                    withdrawalAddress.postalCode
                this.$refs.addressForm.$data.formData.neighborhood =
                    withdrawalAddress.neighborhood
                this.$refs.addressForm.$data.formData.complement =
                    withdrawalAddress.complement
                this.$refs.addressForm.$data.formData.number =
                    withdrawalAddress.number
                this.$refs.addressForm.$data.formData.state =
                    withdrawalAddress.state
                this.$refs.addressForm.$data.formData.city =
                    withdrawalAddress.locality
                this.$refs.addressForm.$data.formData.reference =
                    withdrawalAddress.reference
                this.$refs.addressForm.$data.formData.isMainAddress =
                    withdrawalAddress.isMainAddress
            }, 300)
        },
        schedulingTime(newVal) {
            if (!newVal) return
            const numericValue = newVal.replace(/\D/g, "")
            if (numericValue.length <= 2) {
                this.formData.schedulingTime = numericValue
            } else {
                this.formData.schedulingTime = `${numericValue.slice(
                    0,
                    2
                )}:${numericValue.slice(2)}`
            }
        },
        withdrawalTime(newVal) {
            if (!newVal) return
            const numericValue = newVal.replace(/\D/g, "")
            if (numericValue.length <= 2) {
                this.formData.withdrawalTime = numericValue
            } else {
                this.formData.withdrawalTime = `${numericValue.slice(
                    0,
                    2
                )}:${numericValue.slice(2)}`
            }
        },
        async contact(val) {
            if (!val) return

            try {
                const addressResponse = await Api.findAll("addresses", {
                    filter: {
                        modelId: val,
                        modelType: this.formData.isCompany
                            ? "COMPANY"
                            : "PERSON",
                    },
                })
                this.donorAddressesOptions = addressResponse.data.map(
                    address => ({
                        ...address,
                        value: address.id,
                        label: address.street,
                    })
                )
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
    async mounted() {
        const responses = await Promise.all([
            this.getDeliveryLocations(),
            this.getProductCategories(),
            this.getUnitsOfMeasure(),
            this.getMaterialDonationStatus(),
            this.getAddressClassifications(),
        ])

        this.deliveryLocationOptions = responses[0].data
        this.productCategoriesOptions = responses[1].data
        this.unitsOfMeasurementOptions = responses[2].data
        this.materialDonationStatusOptions = responses[3].data
        this.addressClassificationsOptions = responses[4].data

        this.loading = false
        this.$root.$on("bv::toggle::collapse", (bvEvent, modalId) => {
            if (modalId === "create-material-donations-modal") {
                this.show()
            }
        })
    },

    methods: {
        updateProducts(products) {
            this.formData.products = products
        },
        timeMask(event) {
            if (!event) return

            const inputValue = event.replace(/\D/g, "")

            if (inputValue.length <= 2) {
                this.formData.withdrawalTime = inputValue
            } else {
                this.formData.withdrawalTime = `${inputValue.slice(
                    0,
                    2
                )}:${inputValue.slice(2)}`
            }
        },
        async handleSubmit() {
            let donationResponse
            let addressResponse
            let attachProductsResponse

            const productsResponse = await this.$refs.productsForm.dispatchProductsRequest()
            if (!productsResponse || !productsResponse.length > 0) return

            try {
                donationResponse = await this.createDonation()

                this.materialDonationCreated = donationResponse.data

                addressResponse = await this.$refs.addressForm.dispatchRequest()

                if (!addressResponse) return

                this.formData.products = productsResponse.map(
                    element => element.data
                )

                attachProductsResponse = await this.$refs.productsForm.dispatchRequest(
                    this.materialDonationCreated
                )

                if (!attachProductsResponse) return

                this.$toast(toastMessages.getSuccessMessage())
                this.$formulate.resetValidation("materialDonationCreate")
                this.$emit("materialDonationCreated")
                this.hide()
            } catch (e) {
                this.$formulate.handle(e, "materialDonationCreate")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        createDonation() {
            const contact = (() => {
                if (!this.formData.contact) {
                    return null
                }
                return this.formData.isCompany
                    ? { type: "companies", id: this.formData.contact }
                    : { type: "people", id: this.formData.contact }
            })()

            return Api.create(
                "material-donations",
                {
                    schedulingDate: `${this.formData.schedulingDate} ${this.formData.schedulingTime}`,
                    withdrawalDate: `${this.formData.withdrawalDate} ${this.formData.withdrawalTime}`,
                    observation: this.formData.observation,
                    action: this.formData.action
                        ? { type: "actions", id: this.formData.action }
                        : null,
                    contact,
                    withdrawalAddress: this.formData.withdrawalAddress
                        ? {
                              type: "addresses",
                              id: this.formData.withdrawalAddress,
                          }
                        : null,
                    deliveryLocation: this.formData.deliveryLocation
                        ? {
                              type: "delivery-locations",
                              id: this.formData.deliveryLocation,
                          }
                        : null,
                },
                {
                    include: "action,contact",
                }
            )
        },
        show() {
            this.active = true
            this.setAttributesDefaultValues()
        },
        hide() {
            this.active = false
            this.setAttributesDefaultValues()
        },
        setAttributesDefaultValues() {
            this.formData.action = ""
            this.formData.contact = ""
            this.formData.deliveryLocation = ""
            this.formData.schedulingDate = ""
            this.formData.schedulingTime = ""
            this.formData.withdrawalDate = ""
            this.formData.observation = ""
            this.formData.withdrawalTime = ""
            this.formData.withdrawalAddress = ""
            this.formData.products = []

            if (this.donationContact) {
                this.donorOptions = [
                    {
                        value: this.donationContact.id,
                        label: this.donationContact.name,
                    },
                ]
                this.formData.isCompany = this.donationContactIsCompany
                this.formData.contact = this.donationContact.id
            }
        },
        checkForWithdrawOperation() {
            return true
        },
        getDeliveryLocations() {
            return Api.findAll("delivery-locations", {
                fields: {
                    "delivery-locations":
                        "description,postalCode,addressDescription,number,complement,reference,state,locality,neighborhood",
                },
            })
        },
        getProductCategories() {
            return Api.findAll("product-categories", {
                fields: { "product-categories": "description" },
            })
        },
        getUnitsOfMeasure() {
            return Api.findAll("units-of-measurement", {
                fields: { "units-of-measurement": "description,symbol" },
            })
        },
        getMaterialDonationStatus() {
            return Api.findAll("material-donation-statuses", {
                fields: { "material-donation-statuses": "description" },
            })
        },
        getAddressClassifications() {
            return Api.findAll("address-classifications", {
                fields: { "address-classifications": "description" },
            })
        },
    },
}
</script>
