var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"view-material-donations-modal","centered":"","hideFooter":"","title":_vm.$tc('modules.materialDonations.description', 1),"size":"xl"},on:{"show":_vm.show},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',[_c('formulateForm',{attrs:{"name":"materialDonationEdit"},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}},[_c('fieldset',{attrs:{"disabled":""}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","xl":"3"}},[_c('select-action',{attrs:{"name":"action","placeholder":_vm.$t('common.crud.select') +
                              ' ' +
                              _vm.$tc(
                                  'modules.actions.description',
                                  1
                              ).toLowerCase(),"label":_vm.$tc('modules.actions.description', 1),"validation":"required","options":_vm.actionsOptions,"setDisabled":true},on:{"update:options":function($event){_vm.actionsOptions = $event},"update:input":function($event){_vm.formData.action = $event}}})],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('FormulateInput',{staticClass:"ml-4 mt-2",attrs:{"name":"isCompany","type":"checkbox","label":_vm.$t('modules.materialDonations.isCompany')}})],1),_c('b-col',{attrs:{"cols":"12","xl":"3"}},[(!_vm.formData.isCompany)?_c('select-person',{attrs:{"name":"contact","placeholder":_vm.$t('common.crud.select') +
                              ' ' +
                              _vm.$tc('modules.people.description', 1).toLowerCase(),"label":_vm.$t('modules.materialDonations.donor'),"validation":"required","options":_vm.donorOptions,"setDisabled":true},on:{"update:options":function($event){_vm.donorOptions = $event},"update:input":function($event){_vm.formData.contact = $event}}}):_vm._e(),(_vm.formData.isCompany)?_c('select-company',{attrs:{"label":_vm.$t('modules.materialDonations.donor'),"placeholder":_vm.$t('common.crud.select') +
                              ' ' +
                              _vm.$tc('modules.companies.description', 1).toLowerCase(),"name":"contact","validation":"required","setDisabled":true},on:{"update:options":function($event){_vm.donorOptions = $event},"update:input":function($event){_vm.formData.contact = $event}}}):_vm._e()],1)],1),_c('b-row',[(_vm.materialDonation.products)?_c('material-donation-products-list',{ref:"productsForm",attrs:{"productsList":_vm.materialDonation.products.map(function (product) { return (Object.assign({}, product,
                          {category: product.category ? product.category.id : null,
                          unitOfMeasurement: product.unitOfMeasurement ? product.unitOfMeasurement.id : null})); }),"productsCategories":_vm.productCategoriesOptions,"unitsOfMeasurement":_vm.unitsOfMeasurementOptions,"deliveryLocations":_vm.deliveryLocationOptions,"disabled":true},on:{"updateProductsList":function($event){_vm.formData.products = $event}}}):_vm._e()],1),_c('b-row',{staticClass:"mb-1"},[_c('div',{staticClass:"subsection-title"},[_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "modules.materialDonations.deliveryInformation" ))+" ")])])])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"4"}},[_c('formulateInput',{attrs:{"name":"deliveryLocation","label":_vm.$t('modules.materialDonations.deliveryLocation'),"placeholder":_vm.$t('common.crud.select') +
                              ' ' +
                              _vm.$t(
                                  'modules.materialDonations.deliveryLocation'
                              ).toLowerCase(),"type":"vue-select","validation":"optional","options":_vm.deliveryLocationOptions,"reduceFunc":function (x) { return x.id; },"recudeLabel":"description","disabled":""},on:{"update:input":function($event){_vm.formData.deliveryLocation = $event}}})],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('formulate-input',{attrs:{"disabled":!_vm.checkForDelivery,"name":"schedulingDate","label":_vm.$t('modules.materialDonations.schedulingDate'),"placeholder":_vm.$t('modules.materialDonations.schedulingDate'),"type":"custom-date","validation":"optional|date:YYYY-MM-DD"},on:{"update:date":function($event){_vm.formData.schedulingDate = $event}}})],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('formulateInput',{attrs:{"disabled":true,"name":"schedulingTime","label":_vm.$t('modules.materialDonations.time'),"placeholder":"00:00","type":"text","maxlength":"5","validation":[
                              [
                                  'matches',
                                  '/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/' ],
                              ['optional'] ]},on:{"input":function($event){return _vm.timeMask(_vm.formData.schedulingTime)}}})],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('formulate-input',{attrs:{"name":"withdrawalDate","label":_vm.$t('modules.materialDonations.date'),"placeholder":_vm.$t('modules.materialDonations.date'),"type":"custom-date","validation":"optional|date:YYYY-MM-DD"},on:{"update:date":function($event){_vm.formData.withdrawalDate = $event}}})],1),_c('b-col',{attrs:{"cols":"12","xl":"2"}},[_c('formulateInput',{attrs:{"name":"withdrawalTime","label":_vm.$t('modules.materialDonations.time'),"placeholder":"00:00","type":"text","maxlength":"5","validation":[
                              [
                                  'matches',
                                  '/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/' ],
                              ['optional'] ]},on:{"input":function($event){return _vm.timeMask(_vm.formData.withdrawalTime)}}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"name":"observation","label":_vm.$t('modules.materialDonations.observation'),"placeholder":_vm.$t('modules.materialDonations.observation'),"type":"text","validation":"optional|max:255,length"}})],1)],1),(_vm.checkForWithdrawOperation)?_c('b-row',{staticClass:"mb-1 mt-2"},[_c('div',{staticClass:"subsection-title"},[_c('span',{staticClass:"text-bold"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$t( "modules.materialDonations.withdrawalInformation" ))+" ")])])])]):_vm._e(),(_vm.checkForWithdrawOperation)?_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","xl":"3"}},[_c('formulateInput',{attrs:{"disabled":!_vm.checkForDelivery,"name":"withdrawalAddress","label":_vm.$t('modules.materialDonations.donorAddress'),"placeholder":_vm.$t('common.crud.select') +
                              ' ' +
                              _vm.$t(
                                  'modules.materialDonations.donorAddress'
                              ).toLowerCase(),"type":"vue-select","validation":"optional","options":_vm.donorAddressesOptions.map(function (el) { return ({
                                  value: el.id,
                                  label: el.description,
                              }); })},on:{"update:input":function($event){_vm.formData.withdrawalAddress = $event}}}),_c('div',{staticClass:"mt-3"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"20"}}),_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm.$t("modules.materialDonations.addressName"))+" ")]),_c('div',{staticClass:"mt-2"},[_c('span',[_vm._v(" "+_vm._s((_vm.donorAddressesOptions.find(function (el) { return el.id === _vm.formData.withdrawalAddress; }) || {}).completeAddress || '')+" ")])])],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"9"}},[_c('attach-address-horizontal',{ref:"addressForm",attrs:{"resourceId":_vm.formData.contact ? _vm.formData.contact : '',"resourceClass":"MATERIAL_DONATION","addressEdit":Object.hasOwn(_vm.materialDonation, 'withdrawalAddress') ? _vm.materialDonation.withdrawalAddress : null,"disableForm":!_vm.checkForDelivery,"disableSearch":true}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1)],1),_c('b-row',{staticClass:"justify-content-center align-self-center mt-2 ml-0"},[_c('formulateInput',{staticClass:"pl-2",attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button","disabled":false},on:{"click":_vm.hide}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }