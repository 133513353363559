var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$can('create', 'material-donations'))?_c('material-donation-create',{attrs:{"donationContact":_vm.donationContact,"donationContactIsCompany":_vm.donationContactIsCompany,"donorAddressesOptions":_vm.donationContact && Object.hasOwn(_vm.donationContact, 'addresses')
                ? _vm.donationContact.addresses
                : []},on:{"materialDonationCreated":_vm.getMaterialDonations}}):_vm._e(),(_vm.materialDonationSelected)?_c('material-donation-edit',{attrs:{"materialDonation":_vm.materialDonationSelected,"donationContact":_vm.donationContact,"donationContactIsCompany":_vm.donationContactIsCompany},on:{"materialDonationUpdated":_vm.getMaterialDonations}}):_vm._e(),(_vm.materialDonationSelected)?_c('material-donation-view',{attrs:{"materialDonation":_vm.materialDonationSelected},on:{"materialDonationUpdated":_vm.getMaterialDonations}}):_vm._e(),_c('material-donation-confirmation-modal',{attrs:{"materialDonation":_vm.materialDonationSelected,"modalId":"confirmationModal","materialDonationStatusOptions":_vm.materialDonationStatusOptions},on:{"updateMaterialDonationStatus":_vm.getMaterialDonations}}),_c('material-donation-reopen-modal',{attrs:{"materialDonation":_vm.materialDonationSelected,"modalId":"reopenModal","materialDonationStatusOptions":_vm.materialDonationStatusOptions},on:{"updateMaterialDonationStatus":_vm.getMaterialDonations}}),_c('material-donation-scheduling-modal',{attrs:{"materialDonation":_vm.materialDonationSelected,"modalId":"schedulingModal","materialDonationStatusOptions":_vm.materialDonationStatusOptions},on:{"updateMaterialDonationStatus":_vm.getMaterialDonations}}),_c('material-donation-collection-list-modal'),_c('material-donation-collections-modal'),_c('b-card',[_c('datatable-header',{attrs:{"title":_vm.$tc('modules.materialDonations.description', 2),"genericSearchEnabled":true,"searchTermPlaceHolder":_vm.$t('common.search')},on:{"filterUpdated":_vm.onFilterUpdate},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$root.$emit(
                            'bv::toggle::modal',
                            'create-material-donations-modal'
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.addNew", { resource: _vm.$tc( "modules.materialDonations.description", 1 ).toLowerCase(), }).toString())+" ")]),_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.$root.$emit(
                            'bv::toggle::modal',
                            'material-donation-collection-list-modal'
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("modules.materialDonations.collectionList"))+" ")]),_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$root.$emit(
                            'bv::toggle::modal',
                            'material-donation-collection-modal'
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("modules.materialDonations.collectionList"))+" ")]),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.exportXls}},[_c('feather-icon',{staticClass:"cursor-pointer mr-1",attrs:{"id":"download-button-icon","size":"16","icon":"DownloadIcon"}}),_vm._v(" "+_vm._s(_vm.$t("common.export"))+" "),_c('feather-icon',{staticClass:"cursor-pointer ml-1",attrs:{"id":"download-button-icon","size":"16","icon":"ChevronDownIcon"}})],1)]},proxy:true},{key:"advancedSearch",fn:function(ref){
                        var expanded = ref.expanded;
                        var onFilterChange = ref.onFilterChange;
return [_c('DatatableFilter',{attrs:{"availableDatatableFilters":_vm.availableDatatableFilters,"expanded":expanded},on:{"onFilterChange":onFilterChange}})]}}])}),_c('datatable',{attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"paginationOptions":{
                enabled: true,
                mode: 'pages',
                perPage: _vm.dataTableOptions.pagination.perPage,
            },"rows":_vm.materialDonationList ? _vm.materialDonationList : _vm.rows,"rtl":_vm.direction,"searchOptions":{
                enabled: true,
                externalQuery: _vm.searchTerm,
            },"totalRows":_vm.dataTableOptions.pagination.totalRows,"mode":"remote","theme":"my-theme"},on:{"on-page-change":_vm.handleChangePage,"onPageSizeChange":_vm.handlePageSizeChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[(props.row.action)?_c('a',{staticClass:"text-custom-blue",attrs:{"role":"button"},on:{"mousedown":function($event){return _vm.openInNewTab(
                                $event,
                                'view-actions',
                                props.row.action.id
                            )},"click":function($event){return _vm.$router.push({
                                name: 'view-actions',
                                params: { id: props.row.action.id },
                            })}}},[_vm._v(" "+_vm._s(props.row.action.title)+" ")]):_vm._e()]):(props.column.field === 'status')?_c('span',[(props.row.status)?_c('b-badge',{attrs:{"variant":_vm.statusVariant(
                                props.row.status.systemDefaultStatus
                            )}},[_vm._v(" "+_vm._s(props.row.status.description)+" ")]):_vm._e()],1):(props.column.field === 'contact')?_c('span',[(props.row.contact)?_c('a',{staticClass:"font-weight-bold text-custom-blue",attrs:{"role":"button"},on:{"mousedown":function($event){props.row.contact.type === 'people'
                                ? _vm.openInNewTab(
                                      $event,
                                      'view-people',
                                      props.row.action.id
                                  )
                                : _vm.openInNewTab(
                                      $event,
                                      'view-companies',
                                      props.row.action.id
                                  )},"click":function($event){props.row.contact.type === 'people'
                                ? _vm.$router.push({
                                      name: 'view-people',
                                      params: { id: props.row.contact.id },
                                  })
                                : _vm.$router.push({
                                      name: 'view-companies',
                                      params: { id: props.row.contact.id },
                                  })}}},[_vm._v(" "+_vm._s(props.row.contact.name)+" ")]):_vm._e()]):(props.column.field === 'products')?_c('span',[(props.row.products)?_c('span',[_vm._v(" R$ "+_vm._s(_vm.formatFloat( props.row.products.reduce( function (total, product) { return parseFloat(total) + parseFloat(product.totalValue); }, 0 ) ))+" ")]):_vm._e()]):(props.column.field === 'editor')?_c('span',[(props.row.editor)?_c('span',{staticClass:"font-weight-bold text-custom-blue",attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.push({
                                name: 'view-users',
                                params: { id: props.row.editor.id },
                            })}}},[_vm._v(" "+_vm._s(props.row.editor.name)+" ")]):_vm._e()]):(props.column.field === 'actions')?_c('span',[_c('feather-icon',{staticClass:"cursor-pointer ml-2",attrs:{"id":("form-item-settings-icon-" + (props.index)),"size":"16","icon":"EditIcon"}}),_c('b-popover',{ref:("form-item-settings-popover-" + (props.index)),attrs:{"target":("form-item-settings-icon-" + (props.index)),"triggers":"hover focus","placement":"top"}},[_c('b-button-group',[(
                                    _vm.$can('edit', 'material-donations') &&
                                    props.row.status &&
                                    Object.hasOwn(
                                        props.row.status,
                                        'systemDefaultStatus'
                                    ) &&
                                    [
                                        'open',
                                        'scheduled',
                                        'reopened' ].includes(
                                        props.row.status.systemDefaultStatus
                                    )
                                )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer ml-1 mr-1",attrs:{"id":("form-item-settings-icon-" + (props.index)),"title":_vm.$t('common.crud.edit'),"size":"24","icon":"Edit2Icon"},on:{"click":function($event){return _vm.openModal(
                                        props.row,
                                        'edit-material-donations-modal'
                                    )}}}):_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer ml-1 mr-1",attrs:{"id":("form-item-settings-icon-" + (props.index)),"title":_vm.$t('common.crud.view'),"size":"24","icon":"EyeIcon"},on:{"click":function($event){return _vm.openModal(
                                        props.row,
                                        'view-material-donations-modal'
                                    )}}}),(
                                    props.row.status &&
                                    Object.hasOwn(
                                        props.row.status,
                                        'systemDefaultStatus'
                                    ) &&
                                    [
                                        'open',
                                        'scheduled',
                                        'reopened' ].includes(
                                        props.row.status.systemDefaultStatus
                                    )
                                )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer ml-1 mr-1",attrs:{"id":("form-item-settings-icon-" + (props.index)),"title":_vm.$t(
                                        'modules.materialDonations.schedulingDonation'
                                    ),"size":"24","icon":"ClockIcon"},on:{"click":function($event){return _vm.openModal(props.row, 'schedulingModal')}}}):_vm._e(),(
                                    props.row.status &&
                                    Object.hasOwn(
                                        props.row.status,
                                        'systemDefaultStatus'
                                    ) &&
                                    [
                                        'open',
                                        'scheduled',
                                        'reopened' ].includes(
                                        props.row.status.systemDefaultStatus
                                    )
                                )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer ml-1 mr-1",attrs:{"id":("form-item-settings-icon-" + (props.index)),"title":_vm.$t(
                                        'modules.materialDonations.confirmDonation'
                                    ),"size":"24","icon":"CheckSquareIcon"},on:{"click":function($event){return _vm.openModal(
                                        props.row,
                                        'confirmationModal'
                                    )}}}):_vm._e(),(
                                    props.row.status &&
                                    Object.hasOwn(
                                        props.row.status,
                                        'systemDefaultStatus'
                                    ) &&
                                    [
                                        'confirmed',
                                        'cancelled',
                                        'rejected' ].includes(
                                        props.row.status.systemDefaultStatus
                                    )
                                )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer ml-1 mr-1",attrs:{"id":("form-item-settings-icon-" + (props.index)),"title":_vm.$t(
                                        'modules.materialDonations.reopenDonation'
                                    ),"size":"24","icon":"PackageIcon"},on:{"click":function($event){return _vm.openModal(props.row, 'reopenModal')}}}):_vm._e(),(
                                    _vm.$can(
                                        'print-receipt',
                                        'material-donations'
                                    ) &&
                                    Object.hasOwn(
                                        props.row.status,
                                        'systemDefaultStatus'
                                    ) &&
                                    !['cancelled', 'rejected'].includes(
                                        props.row.status.systemDefaultStatus
                                    )
                                )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"cursor-pointer ml-1 mr-1",attrs:{"id":("form-item-settings-icon-" + (props.index)),"title":_vm.$t(
                                        'modules.materialDonations.donationReceipt'
                                    ),"size":"24","icon":"ClipboardIcon"},on:{"click":function($event){return _vm.downloadReceipt(props.row.id)}}}):_vm._e()],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }