var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('formulateForm',{attrs:{"name":"peopleEditForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
var hasErrors = ref.hasErrors;
return [_c('b-card',{staticClass:"mt-2"},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"section-header"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"14"}}),_vm._v(" "+_vm._s(_vm.$t("modules.people.profile"))+" ")],1)]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('formulateInput',{attrs:{"label":_vm.$t('common.crud.name'),"name":"name","placeholder":_vm.$t('common.crud.name'),"type":"text","validation":"required|max:200,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('common.status'),"name":"status","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional","options":_vm.statusOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.status = $event}}})],1),(_vm.isStatusInactive(_vm.formData.status))?_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.inactivationReason'),"placeholder":_vm.$t('common.crud.select'),"name":"inactivationReason","type":"vue-select","validation":"optional|max:32,length","options":_vm.inactivationReasonOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.inactivationReason = $event}}})],1):_vm._e()],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.salutation'),"name":"salutation","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional","options":_vm.salutationsOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.salutation = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.gender'),"name":"gender","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional","options":_vm.gendersOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.gender = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.documentNumber'),"name":"documentNumber","placeholder":_vm.$t('modules.people.documentNumber'),"type":"text","validation":"optional|cpf","validationMessages":{
                            cpf: _vm.$t(
                                'common.crud.errors.invalidDocumentNumber'
                            ),
                        }}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.identityCard'),"name":"identityCard","placeholder":_vm.$t('modules.people.identityCard'),"type":"text","validation":"optional|max:200,length"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulate-input',{attrs:{"label":_vm.$t('modules.people.birthdate'),"placeholder":_vm.$t('customFormats.date'),"name":"birthdate","type":"custom-date","validation":"optional|date:YYYY-MM-DD"},on:{"update:date":function($event){_vm.formData.birthDate = $event}},model:{value:(_vm.formData.birthDate),callback:function ($$v) {_vm.$set(_vm.formData, "birthDate", $$v)},expression:"formData.birthDate"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.scholarity'),"name":"scholarity","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional","options":_vm.scholaritiesOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.scholarity = $event}}})],1)],1)],1),_c('b-card',{staticClass:"mt-1"},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"section-header"},[_c('feather-icon',{attrs:{"icon":"InfoIcon","size":"14"}}),_vm._v(" "+_vm._s(_vm.$t("modules.people.contactInformation"))+" ")],1)]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.origin'),"name":"origin","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional|max:32,length","options":_vm.originsOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.origin = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.organizationContact'),"name":"organizationContact","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional","options":_vm.organizationsContactsOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.organizationContact = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.correspondence'),"name":"correspondence","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional","options":_vm.correspondencesOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.correspondence = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('formulateInput',{attrs:{"label":_vm.$t('modules.people.newsLetter'),"name":"newsletter","placeholder":_vm.$t('common.crud.select'),"type":"vue-select","validation":"optional","options":_vm.newslettersOptions.map(function (el) { return ({
                                value: el.id,
                                label: el.description,
                            }); })},on:{"update:input":function($event){_vm.formData.newsletter = $event}}})],1)],1)],1),_c('b-card',{staticClass:"mt-1"},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"section-header"},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon","size":"14"}}),_vm._v(" "+_vm._s(_vm.$t("modules.people.contactInformationAtCompany"))+" ")],1)]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('select-company',{attrs:{"label":_vm.$tc('modules.companies.description', 1),"placeholder":_vm.$t('common.crud.select'),"name":"companyId","options":_vm.companies,"validation":"optional"},on:{"update:input":function($event){_vm.formData.companyId = $event},"update:options":function($event){_vm.companies = $event}}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.jobDescription'),"name":"jobDescription","placeholder":_vm.$t('modules.people.jobDescription'),"type":"text","validation":"optional|max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.primaryContact'),"name":"isPrimaryContact","placeholder":_vm.$t('common.crud.select'),"type":"select","options":{
                            true: _vm.$t('common.yes'),
                            false: _vm.$t('common.no'),
                        }}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.extension'),"name":"extension","placeholder":_vm.$t('modules.people.extension'),"type":"text","validation":"optional|extension","validationMessages":{
                            extension: _vm.$t(
                                'common.crud.errors.invalidExtension'
                            ),
                        }}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistent'),"name":"assistent","placeholder":_vm.$t('modules.people.assistent'),"type":"text","validation":"max:255,length"}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistentPhone'),"name":"assistentPhone","placeholder":_vm.$t('modules.people.assistentPhone'),"type":"text","validation":"optional|phone","validationMessages":{
                            phone: _vm.$t('common.crud.errors.invalidPhone'),
                        }}})],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistentExtension'),"name":"assistentExtension","placeholder":_vm.$t('modules.people.assistentExtension'),"type":"text","validation":"optional|extension","validationMessages":{
                            extension: _vm.$t(
                                'common.crud.errors.invalidExtension'
                            ),
                        }}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('FormulateInput',{attrs:{"label":_vm.$t('modules.people.assistentEmail'),"name":"assistentEmail","placeholder":_vm.$t('modules.people.assistentEmail'),"type":"text","validation":"optional|max:255,length|email"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1),_c('b-row',{staticClass:"justify-content-center align-self-center mt-2 ml-0"},[_c('formulateInput',{staticClass:"pr-2",attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                        ? _vm.$t('common.crud.updating')
                        : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"}}),_c('formulateInput',{staticClass:"pl-2",attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":function($event){return _vm.$emit('closeEditForm')}}})],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }