var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._b({ref:"attach-note",attrs:{"centered":"","title":_vm.$tc('common.crud.attaching', 2) +
        ' ' +
        _vm.$tc('modules.notes.description', 1).toLowerCase(),"hideFooter":""},on:{"show":_vm.setAttributesDefaultValues}},'b-modal',_vm.$attrs,false),[_c('b-row',[_c('b-col',[_c('FormulateForm',{attrs:{"name":"noteForm"},on:{"submit":_vm.handleSubmit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isLoading = ref.isLoading;
        var hasErrors = ref.hasErrors;
return [_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.description'),"name":"description","placeholder":_vm.$t('common.crud.description'),"type":"textarea","validation":"required|max:2048,length"}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateErrors')],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[(!_vm.note)?_c('FormulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                    ? _vm.$tc('common.crud.attaching', 1)
                                    : _vm.$tc('common.crud.attaching', 2),"inputClass":"btn btn-primary","type":"submit"}}):_c('FormulateInput',{attrs:{"disabled":hasErrors || isLoading,"label":isLoading
                                    ? _vm.$tc('common.crud.updating', 1)
                                    : _vm.$tc('common.crud.update', 2),"inputClass":"btn btn-primary","type":"submit"}})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":_vm.hideModal}})],1)],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }