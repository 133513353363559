<template>
    <div>
        <div v-if="loading" class="text-center">
            <b-spinner variant="primary" class="" />
        </div>
        <div v-else>
            <!-- Alert: No item found -->
            <b-alert variant="danger" :show="personData === undefined">
                <h4 class="alert-heading">
                    {{ $t("common.crud.errors.fetch", { resource: "person" }) }}
                </h4>
                <div class="alert-body">
                    {{
                        $t("common.crud.errors.notfound", {
                            resource: "person",
                        })
                    }}
                    <b-link class="alert-link" :to="{ name: 'people' }">
                        {{
                            $t("common.crud.errors.list", {
                                resource: "person",
                            })
                        }}
                    </b-link>
                    {{
                        $t("common.crud.errors.resource", {
                            resource: "people",
                        })
                    }}
                </div>
            </b-alert>

            <b-row>
                <b-col cols="12" md="12">
                    <people-view-profile-image
                        v-if="personData && !loading"
                        :personData="personData"
                        :relationshipsOptions="relationshipsOptions"
                        :classificationsOptions="classificationsOptions"
                        @openEditResourceTab="activeTab = 5"
                    >
                    </people-view-profile-image>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="3">
                    <contact-card
                        v-if="personData && !loading"
                        resourceType="people"
                        :resourceData="personData"
                        :phoneClassificationsOptions="
                            phoneClassificationsOptions
                        "
                        @deletePhone="
                            personData.phones = personData.phones.filter(
                                (el) => el.id !== $event.id
                            )
                        "
                        @attachPhone="getRelatedPhones"
                        @deleteEmail="
                            personData.emails = personData.emails.filter(
                                (el) => el.id !== $event.id
                            )
                        "
                        @attachEmail="getRelatedEmails"
                    >
                    </contact-card>

                    <main-address-card-view
                        v-if="personData && !loading"
                        class="pb-0 mb-0 mt-3"
                        resourceType="people"
                        :resourceData="personData"
                        @updateActiveTab="
                            (value) => {
                                activeTab = value;
                            }
                        "
                    >
                    </main-address-card-view>

                    <company-card
                        v-if="personData && !loading"
                        resourceType="people"
                        class="pb-0 mb-0 mt-3"
                        :resourceData="personData"
                        @updateActiveTab="
                            (value) => {
                                activeTab = value;
                            }
                        "
                        @deleteCompany="
                            personData = { ...personData, company: null }
                        "
                        @updateCompany="getCompanyRelationship"
                    >
                    </company-card>
                </b-col>

                <b-col cols="12" md="9">
                    <b-tabs
                        v-model="activeTab"
                        pills
                        navClass="nav-pill-primary"
                        navWrapperClass="submenu"
                        class="pill-component"
                    >
                        <b-tab>
                            <template #title>
                                <feather-icon
                                    icon="PieChartIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.general") }}
                                </span>
                            </template>
                            <b-tabs
                                pills
                                navClass="nav-pill-primary"
                                navWrapperClass="submenu"
                                class="pill-component"
                            >
                                <b-tab :title="$t('common.tasks')" disabled>
                                    <!--                                    <TasksList-->
                                    <!--                                        v-if="personData && !loading"-->
                                    <!--                                        :resourceData="personData"-->
                                    <!--                                        resourceType="people"-->
                                    <!--                                    >-->
                                    <!--                                    </TasksList>-->
                                </b-tab>
                                <b-tab :title="$t('common.notes')" active>
                                    <NotesList
                                        v-if="personData && !loading"
                                        :resourceData="personData"
                                        resourceType="people"
                                        @attachNote="attachNote($event)"
                                    >
                                    </NotesList>
                                </b-tab>
                                <b-tab
                                    :title="$t('common.crud.attachments')"
                                    disabled
                                >
                                    <b-card
                                        :title="$t('common.crud.attachments')"
                                    ></b-card>
                                </b-tab>
                                <b-tab
                                    :title="$t('common.crud.operations')"
                                    disabled
                                >
                                    <b-card
                                        :title="$t('common.crud.operations')"
                                    ></b-card>
                                </b-tab>
                            </b-tabs>
                        </b-tab>

                        <b-tab class="pr-1" disabled>
                            <template #title>
                                <feather-icon
                                    icon="DollarSignIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.financialDonations") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab>
                            <template #title>
                                <feather-icon
                                    icon="PackageIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.materialDonations") }}
                                </span>
                            </template>
                            <material-donation-list
                                v-if="personData.materialDonations"
                                :donationContact="personData"
                                :donationContactIsCompany="false"
                                :materialDonationList="
                                    personData.materialDonations
                                "
                                @getRelatedMaterialDonations="
                                    getMaterialDonationRelationship
                                "
                            >
                            </material-donation-list>
                        </b-tab>

                        <b-tab disabled>
                            <template #title>
                                <feather-icon
                                    icon="ListIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.recurrenceList") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab disabled>
                            <template #title>
                                <feather-icon
                                    icon="ClipboardIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("common.crud.volunteerDetails") }}
                                </span>
                            </template>
                        </b-tab>

                        <b-tab
                            v-if="$can('edit people')"
                            card
                            :titleItemClass="activeTab !== 5 ? 'd-none' : ''"
                        >
                            <template #title>
                                <feather-icon
                                    icon="UserIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $t("modules.people.profile") }}
                                </span>
                            </template>
                            <person-edit
                                v-if="
                                    personData &&
                                    Object.hasOwn(personData, 'company') &&
                                    !loading
                                "
                                :personData="personData"
                                :gendersOptions="gendersOptions"
                                :originsOptions="originsOptions"
                                :statusOptions="statusOptions"
                                :relationshipsOptions="relationshipsOptions"
                                :classificationsOptions="classificationsOptions"
                                :salutationsOptions="salutationsOptions"
                                :correspondencesOptions="correspondencesOptions"
                                :scholaritiesOptions="scholaritiesOptions"
                                :newslettersOptions="newslettersOptions"
                                :organizationsContactsOptions="
                                    organizationsContactsOptions
                                "
                                :inactivationReasonOptions="
                                    inactivationReasonOptions
                                "
                                @update:person="updatePersonData($event)"
                                @closeEditForm="activeTab = 1"
                            >
                            </person-edit>
                        </b-tab>

                        <b-tab
                            v-if="$can('edit people')"
                            card
                            :titleItemClass="activeTab !== 6 ? 'd-none' : ''"
                        >
                            <template #title>
                                <feather-icon
                                    icon="MapPinIcon"
                                    size="18"
                                ></feather-icon>
                                <span style="margin-left: 0.5rem">
                                    {{ $tc("common.address.description", 2) }}
                                </span>
                            </template>
                            <addresses-list
                                v-if="personData && !loading"
                                :resourceData="personData"
                                resourceType="people"
                                :addressClassificationsOptions="
                                    addressClassificationsOptions
                                "
                                @deleteAddress="
                                    personData.addresses = personData.addresses.filter(
                                        (el) => el.id !== $event.id
                                    )
                                "
                                @attachAddress="getRelatedAddresses"
                            >
                            </addresses-list>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import {
    BAlert,
    BLink,
    // BButton,
    // BButtonGroup,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BSpinner,
} from "bootstrap-vue"
import router from "@/router"
import PeopleViewProfileImage from "@/views/app/people/people-view/PeopleViewProfileImage.vue"
import MainAddressCardView from "@/views/components/utilis-spa/address/MainAddressCardView.vue"
import Api from "@/services/api"
import ContactCard from "@/views/components/utilis-spa/contacts/ContactCard.vue"
import AddressesList from "@/views/components/utilis-spa/address/AddressesList.vue"
import _ from "lodash"
import MaterialDonationList from "@/views/app/material-donations/MaterialDonationList.vue"
import CompanyCard from "./CompanyCard.vue"
import PersonEdit from "../people-edit/PersonEdit.vue"
import NotesList from "../../notes/notes-list/NotesList.vue"

export default {
    components: {
        PeopleViewProfileImage,
        MainAddressCardView,
        MaterialDonationList,
        CompanyCard,
        BAlert,
        BLink,
        BRow,
        BCol,
        // BButton,
        // BButtonGroup,
        // TasksList,
        NotesList,
        BTab,
        BTabs,
        BCard,
        BSpinner,
        PersonEdit,
        AddressesList,
        ContactCard,
    },

    data() {
        return {
            classificationsOptions: [],
            relationshipsOptions: [],
            statusOptions: [],
            originsOptions: [],
            gendersOptions: [],
            salutationsOptions: [],
            correspondencesOptions: [],
            scholaritiesOptions: [],
            newslettersOptions: [],
            organizationsContactsOptions: [],
            phoneClassificationsOptions: [],
            addressClassificationsOptions: [],
            companyPeople: [],
            inactivationReasonOptions: [],
            activeTab: 0,
            personData: null,
            loading: true,
        }
    },
    watch: {
        async $route(to, from) {
            // Watching route to reload resource data when redirecting to the same route name
            if (to.name === from.name) {
                this.loading = true
                this.personData = null
                const response = await this.getPersonData(to.params.id)
                this.personData = response.data
                await this.getCompanyRelationship()
                this.loading = false
            }
        },
    },
    async mounted() {
        const responses = await Promise.all([
            this.getClassifications(),
            this.getRelationships(),
            this.getStatus(),
            this.getOrigins(),
            this.getGenders(),
            this.getSalutationsOptions(),
            this.getCorrespondencesOptions(),
            this.getSholaritiesOptions(),
            this.getNewslettersOptions(),
            this.getOrganizationsContactsOptions(),
            this.getPhoneClassifications(),
            this.getAddressClassifications(),
            this.getInactivationReasons(),
            this.getPeople(router.currentRoute.params.id),
            this.getPersonData(router.currentRoute.params.id),
        ])

        this.classificationsOptions = responses[0].data
        this.relationshipsOptions = responses[1].data
        this.statusOptions = responses[2].data
        this.originsOptions = responses[3].data
        this.gendersOptions = responses[4].data
        this.salutationsOptions = responses[5].data
        this.correspondencesOptions = responses[6].data
        this.scholaritiesOptions = responses[7].data
        this.newslettersOptions = responses[8].data
        this.organizationsContactsOptions = responses[9].data
        this.phoneClassificationsOptions = responses[10].data
        this.addressClassificationsOptions = responses[11].data
        this.inactivationReasonOptions = responses[12].data
        this.companyPeople = responses[13].data
        this.personData = responses[14].data
        this.personData.materialDonations = this.personData.materialDonations.map(
            donation => ({
                ...donation,
                contact: {
                    ...donation.contact,
                    name:
                        donation.contact && donation.contact.name
                            ? donation.contact.name
                            : this.personData.name,
                },
            })
        )

        const donationsIdFilter = this.personData.materialDonations
            .map(donation => donation.id)
            .join(",")

        const materialDonationItemResponse = await Api.findAll(
            "material-donation-items",
            {
                filter: {
                    materialDonationId: donationsIdFilter,
                },
                // SPARSE FIELDSETS
                fields: {
                    "material-donation-items":
                        "productId,materialDonationId,quantity,unitValue,totalValue",
                },
            }
        )

        this.personData.materialDonations = this.personData.materialDonations.map(
            donation => {
                const products = donation.products.map(product => {
                    const item = materialDonationItemResponse.data.find(
                        materialDonationItem =>
                            parseInt(product.id, 10) ===
                            materialDonationItem.productId
                    )
                    return { ...product, ...item }
                })
                return { ...donation, ...{ products } }
            },
            this
        )

        // // Merges people and companiesPeople data
        const companyPeople = this.personData.companies
            .map(company => {
                const cPeople = this.companyPeople.find(
                    cpPerson =>
                        parseInt(company.id, 10) === cpPerson.companyId
                )
                return { ...company, ...cPeople }
            })
            .find(el => el.id === this.companyPeople[0].id)

        // ONLY LAST UPDATED COMPANY PERSON RESOURCE REMAINS
        this.personData.company = companyPeople

        this.loading = false
    },
    destroyed() {
        this.personData = null
    },
    methods: {
        getPersonData(id) {
            return Api.find("people", id, {
                include:
                    "gender,classifications,origin,status,relations,phones,emails,addresses,creator,editor,destroyer,notes," +
                    "correspondence,inactivationReason,salutation,scholarity,newsletter,organizationContact,phones.phoneClassification," +
                    "notes.editor,notes.creator,companies,companies.status,companies.phones,companies.emails,addresses.addressClassification,notes.editor,notes.creator," +
                    "materialDonations,materialDonations.products,materialDonations.action,materialDonations.status," +
                    "materialDonations.contact,materialDonations.deliveryLocation," +
                    "materialDonations.withdrawalAddress,materialDonations.products,materialDonations.products.category," +
                    "materialDonations.products.unitOfMeasurement,editor",

                fields: {
                    genders: "description",
                    "material-donations":
                        "observation,withdrawalDate,schedulingDate,materialDonationStatusReason" +
                        ",action,status,contact,withdrawalAddress,deliveryLocation,products",
                    "person-relationships": "description",
                    "person-classifications": "description",
                    status: "description",
                    origin: "description",
                    notes: "description,editor,creator",
                    correspondences: "description",
                    salutations: "description",
                    scholarities: "description",
                    newsletters: "description",
                    "organizations-contacts": "description",
                    emails: "address,isMainEmailAddress",
                    phones: "number,isMainPhoneNumber,phoneClassification",
                    users: "name",
                    addresses:
                        "postalCode,isMainAddress,reference,number,complement,description,completeAddress,street," +
                        "state,locality,neighborhood,addressClassification",
                    actions: "title",
                    "material-donation-statuses":
                        "description,systemDefaultStatus",
                    products: "description,category,unitOfMeasurement",
                    "delivery-locations": "description",
                },
            })
        },
        updatePersonData(person) {
            this.personData = person
            this.getCompanyRelationship()
        },

        async getCompanyRelationship() {
            const company = await Api.one("people", this.personData.id)
                .all("companies")
                .get()
            const i = 0
            this.personData = { ...this.personData, company: company.data[i] }
        },

        async getMaterialDonationRelationship() {
            const donationResponse = await Api.one("people", this.personData.id)
                .all("material-donations")
                .get({
                    include:
                        "action,status,contact,products,withdrawalAddress,editor,deliveryLocation," +
                        "products.category,products.unitOfMeasurement",
                })

            const donationsIdFilter = donationResponse.data
                .map(donation => donation.id)
                .join(",")

            const materialDonationItemResponse = await Api.findAll(
                "material-donation-items",
                {
                    filter: {
                        materialDonationId: donationsIdFilter,
                    },
                    // SPARSE FIELDSETS
                    fields: {
                        "material-donation-items":
                            "productId,materialDonationId,quantity,unitValue,totalValue",
                    },
                }
            )

            const materialDonationItems = donationResponse.data.map(
                donation => {
                    const products = donation.products.map(product => {
                        const item = materialDonationItemResponse.data.find(
                            materialDonationItem =>
                                parseInt(product.id, 10) ===
                                materialDonationItem.productId
                        )
                        const index = materialDonationItemResponse.data.indexOf(
                            item
                        )
                        materialDonationItemResponse.data.splice(index, 1)
                        return { ...product, ...item }
                    })
                    return { ...donation, ...{ products } }
                },
                this
            )
            this.personData = {
                ...this.personData,
                materialDonations: materialDonationItems,
            }
        },

        getClassifications() {
            return Api.findAll("person-classifications", {
                fields: { "person-classifications": "description" },
            })
        },

        async getRelatedAddresses() {
            const addresses = await Api.one(
                "people",
                router.currentRoute.params.id
            )
                .all("addresses")
                .get({ include: "addressClassification" })

            this.personData = { ...this.personData, addresses: addresses.data }
        },

        async getRelatedPhones() {
            const phones = await Api.one(
                "people",
                router.currentRoute.params.id
            )
                .all("phones")
                .get({ include: "phoneClassification" })

            this.personData = { ...this.personData, phones: phones.data }
        },

        async getRelatedEmails() {
            const emails = await Api.one(
                "people",
                router.currentRoute.params.id
            )
                .all("emails")
                .get()

            this.personData = { ...this.personData, emails: emails.data }
        },

        attachNote(notes) {
            const newPerson = _.cloneDeep(this.personData)
            newPerson.notes = notes

            this.personData = newPerson
        },

        getRelationships() {
            return Api.findAll("person-relationships", {
                fields: { "person-relationships": "description" },
            })
        },

        getStatus() {
            return Api.findAll("status", {
                fields: { status: "description" },
            })
        },

        getOrigins() {
            return Api.findAll("origin", {
                fields: { origin: "description" },
            })
        },

        getGenders() {
            return Api.findAll("genders", {
                fields: { genders: "description" },
            })
        },

        getSalutationsOptions() {
            return Api.findAll("salutations", {
                fields: { salutations: "description" },
            })
        },

        getCorrespondencesOptions() {
            return Api.findAll("correspondences", {
                fields: { correspondences: "description" },
            })
        },

        getSholaritiesOptions() {
            return Api.findAll("scholarities", {
                fields: { scholarities: "description" },
            })
        },

        getNewslettersOptions() {
            return Api.findAll("newsletters", {
                fields: { newsletters: "description" },
            })
        },

        getOrganizationsContactsOptions() {
            return Api.findAll("organizations-contacts", {
                fields: { "organizations-contacts": "description" },
            })
        },

        getPhoneClassifications() {
            return Api.findAll("phone-classifications", {
                fields: { "phone-classifications": "description" },
            })
        },

        getAddressClassifications() {
            return Api.findAll("address-classifications", {
                fields: { "address-classifications": "description" },
            })
        },

        getInactivationReasons() {
            return Api.findAll("inactivation-reasons", {
                fields: { "inactivation-reasons": "description" },
            })
        },

        getPeople(personId) {
            return Api.findAll("companies-people", {
                filter: { personId },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.profile-menu {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    border-radius: 5px;
    color: #6e6b7b !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
}

.profile-menu-selected {
    background-color: #519bd6 !important;
    border-color: #519bd6 !important;
    border-radius: 5px;
    color: #ffffff !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.4px;
}

.nav-tabs .nav-item .nav-link {
    font-size: 1rem;
    line-height: 1rem;
    color: #5e5873;
}

.pill-component {
    width: 99%;
}
</style>
