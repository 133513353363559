var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.resourceData)?_c('create-address',_vm._g({attrs:{"id":"create-addresses-modal","resourceType":_vm.resourceType,"resourceData":_vm.resourceData,"addressEdit":_vm.addressEdit,"addressClassificationsOptions":_vm.addressClassificationsOptions},on:{"hide":function($event){_vm.addressEdit = null}}},_vm.$listeners)):_vm._e(),_c('b-card',[_c('datatable-header',{attrs:{"title":_vm.$tc('common.address.description', 2),"genericSearchEnabled":true,"searchTermPlaceHolder":_vm.$t('common.search')},on:{"filterUpdated":_vm.onFilterUpdate},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('b-button',{staticClass:"ml-2",staticStyle:{"background-color":"#519bd6"},attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$root.$emit(
                            'bv::toggle::modal',
                            'create-addresses-modal'
                        )}}},[_vm._v(" "+_vm._s(_vm.$t("common.crud.addNew", { resource: _vm.$tc( "common.address.description", 1 ).toLowerCase(), }).toString())+" ")])]},proxy:true},{key:"advancedSearch",fn:function(ref){
                        var expanded = ref.expanded;
                        var onFilterChange = ref.onFilterChange;
return [_c('DatatableFilter',{attrs:{"availableDatatableFilters":_vm.availableDatatableFilters,"expanded":expanded},on:{"filterUpdated":_vm.onFilterUpdate,"onFilterChange":onFilterChange}})]}}])}),_c('datatable',{attrs:{"columns":_vm.columns,"isLoading":_vm.isLoading,"paginationOptions":{
                enabled: false,
            },"rows":_vm.orderAddresses,"rtl":_vm.direction,"searchOptions":{
                enabled: false,
                externalQuery: _vm.searchTerm,
            },"totalRows":_vm.dataTableOptions.pagination.totalRows,"theme":"my-theme"},on:{"on-page-change":_vm.handleChangePage,"onPageSizeChange":_vm.handlePageSizeChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'isMainAddress')?_c('span',[_c('b-badge',{class:_vm.applyClass(
                                props.row.isMainAddress
                                    ? props.row.isMainAddress
                                    : false
                            )},[_vm._v(" "+_vm._s(props.row.isMainAddress ? _vm.$t("common.primary") : _vm.$t("common.secondary"))+" ")])],1):(props.column.field === 'actions')?_c('span',[_c('span',{staticClass:"d-inline"},[_c('feather-icon',{directives:[{name:"b-modal",rawName:"v-b-modal.create-addresses-modal",modifiers:{"create-addresses-modal":true}}],staticStyle:{"cursor":"pointer"},attrs:{"icon":"Edit2Icon","size":"20"},on:{"click":function($event){_vm.addressEdit = props.row}}})],1),_c('span',{staticClass:"d-inline pl-1"},[_c('feather-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"Trash2Icon","size":"20"},on:{"click":function($event){return _vm.onDeleteClick(props.row)}}})],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }