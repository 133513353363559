<template>
    <b-modal
        v-bind="$attrs"
        ref="attach-note"
        centered
        :title="
            $tc('common.crud.attaching', 2) +
            ' ' +
            $tc('modules.notes.description', 1).toLowerCase()
        "
        hideFooter
        @show="setAttributesDefaultValues"
    >
        <b-row>
            <b-col>
                <FormulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="noteForm"
                    @submit="handleSubmit"
                >
                    <FormulateInput
                        :label="$t('common.crud.description')"
                        name="description"
                        :placeholder="$t('common.crud.description')"
                        type="textarea"
                        validation="required|max:2048,length"
                    />

                    <b-row class="mt-3">
                        <b-col class="d-flex justify-content-center">
                            <FormulateErrors />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                v-if="!note"
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $tc('common.crud.attaching', 1)
                                        : $tc('common.crud.attaching', 2)
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                            />
                            <FormulateInput
                                v-else
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $tc('common.crud.updating', 1)
                                        : $tc('common.crud.update', 2)
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                            />
                        </b-col>

                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                :label="$t('common.crud.close')"
                                inputClass="btn btn-danger"
                                type="button"
                                @click="hideModal"
                            />
                        </b-col>
                    </b-row>
                </FormulateForm>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { BModal, BRow, BCol } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BModal,
        BRow,
        BCol,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
        note: {
            required: false,
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            formData: {
                description: "",
            },
            service: Api,
        }
    },
    methods: {
        setAttributesDefaultValues() {
            this.formData.description = ""
            if (this.note) this.formData.description = this.note.description
        },
        async handleSubmit() {
            try {
                // CREATING NEW NOTE
                if (this.note === null) {
                    const creator = {
                        creator: JSON.parse(localStorage.getItem("userData")),
                    }
                    const note = await this.service.create("notes", {
                        description: this.formData.description,
                        notable: {
                            type: this.$props.resourceType,
                            id: this.resourceData.id,
                        },
                    })

                    this.$emit("attachOrUpdateNote", {
                        ...note.data,
                        ...creator,
                    })
                }

                // UPDATING NOTE
                else {
                    const userStamp = {
                        editor: JSON.parse(localStorage.getItem("userData")),
                        creator: this.note.creator,
                    }
                    const note = await this.service.update("notes", {
                        id: this.note.id,
                        description: this.formData.description,
                        notable: {
                            type: this.$props.resourceType,
                            id: this.resourceData.id,
                        },
                    })

                    this.$emit("attachOrUpdateNote", {
                        ...note.data,
                        ...userStamp,
                    })
                }

                this.$refs["attach-note"].hide()
                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        hideModal() {
            this.$refs["attach-note"].hide()
        },
    },
}
</script>

<style scoped></style>
