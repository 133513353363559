var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('formulateInput',{attrs:{"label":_vm.label,"name":_vm.name,"placeholder":_vm.placeholder,"type":"vue-select-async","disabled":_vm.setDisabled,"validation":_vm.validation,"apiData":{
        resource: 'companies',
        fields: { companies: 'name' },
        fieldsMapping: {
            label: 'name',
            value: 'id',
        },
    },"options":_vm.options},on:{"update:input":function($event){return _vm.$emit('update:input', $event)},"update:options":function($event){return _vm.$emit('update:options', $event)}}})}
var staticRenderFns = []

export { render, staticRenderFns }