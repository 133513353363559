<template>
    <b-card>
        <div class="d-block">
            <feather-icon icon="MapPinIcon" size="28" />
            <h4 class="d-inline">
                {{ $tc("common.address.description", 2) }}
            </h4>
            <a
                class="pl-2 a-link"
                @click="
                    $root.$emit('bv::show::modal', 'create-addresses-modal')
                "
            >
                <p class="under-blue-text d-inline">
                    +
                    {{
                        $t("common.crud.addNew", {
                            resource: $tc("common.address.description", 1),
                        })
                    }}
                </p>
            </a>
            <feather-icon
                icon="ChevronDownIcon"
                size="28"
                class="float-right"
            />
        </div>

        <b-row v-if="resourceData" class="mt-1">
            <b-col
                v-for="address in resourceData.addresses.filter(
                    (el) => el.isMainAddress === true
                )"
                :key="address.id"
                cols="6"
                class="border rounded ml-2"
            >
                <div class="mt-1">
                    <h4 class="mt-1" style="font-size: 1rem">
                        {{ address.addressType }}
                    </h4>
                    <p>
                        {{ address.completeAddress }}
                    </p>
                </div>
            </b-col>

            <b-col cols="5" class="mt-5">
                <a class="pl-2 a-link" @click="$emit('updateActiveTab', 6)">
                    <p class="under-blue-text d-inline">
                        {{ $t("common.crud.seeAll") }}
                    </p>
                </a>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue"

export default {
    components: {
        BCard,
        BRow,
        BCol,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
    },
}
</script>
