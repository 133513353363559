<template>
    <!-- Items Section -->
    <b-card-body class="invoice-padding form-item-section pt-0 pl-1">
        <div ref="form" class="repeater-form">
            <b-row ref="row" class="pb-2">
                <!-- Item Form -->
                <!-- ? This will be in loop => So consider below markup for single item -->
                <b-col cols="12 pl-1">
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-none d-lg-flex mb-1">
                        <b-row class="flex-grow-1 px-1 subsection-title">
                            <!-- Single Item Form Headers -->
                            <b-col
                                cols="12"
                                lg="5"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.productsList.product"
                                        )
                                    }}
                                </strong>
                            </b-col>
                            <b-col
                                cols="12"
                                lg="2"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.productsList.category"
                                        )
                                    }}
                                </strong>
                            </b-col>
                            <b-col
                                cols="12"
                                lg="2"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.productsList.type"
                                        )
                                    }}
                                </strong>
                            </b-col>
                            <b-col
                                cols="12"
                                lg="1"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.productsList.quantity"
                                        )
                                    }}
                                </strong>
                            </b-col>
                            <b-col
                                cols="12"
                                lg="1"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.productsList.unitValue"
                                        )
                                    }}
                                </strong>
                            </b-col>
                            <b-col
                                cols="12"
                                lg="1"
                                class="d-flex align-items-center justify-content-center"
                            >
                                <strong>
                                    {{
                                        $t(
                                            "modules.materialDonations.productsList.totalValue"
                                        )
                                    }}
                                </strong>
                            </b-col>
                        </b-row>
                    </div>

                    <formulateForm
                        ref="formRef"
                        v-model="formData"
                        name="productsForm"
                        @submit="dispatchProductsRequest"
                    >
                        <FormulateInput
                            type="group"
                            name="products"
                            :repeatable="true"
                            validation="required"
                            :groupErrors="formErrors"
                        >
                            <template #remove="{ index, removeItem }">
                                <feather-icon
                                    v-if="!disabled"
                                    :key="index"
                                    size="24"
                                    icon="Trash2Icon"
                                    stroke="#519BD6"
                                    class="cursor-pointer float-right"
                                    @click="removeItem(index)"
                                />
                            </template>

                            <template #addmore="{ addMore }">
                                <b-button
                                    v-if="!disabled"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="md"
                                    variant="primary"
                                    class="float-right mr-5 mb-2"
                                    @click="addMore"
                                >
                                    {{
                                        $t(
                                            "modules.materialDonations.addNewMaterial"
                                        )
                                    }}
                                </b-button>
                            </template>

                            <template #default="{ index }">
                                <b-row class="flex-grow-1">
                                    <!-- Single Item Form Headers -->
                                    <b-col cols="12" lg="5" class="pl-0">
                                        <label class="d-inline d-lg-none">
                                            {{
                                                $t(
                                                    "modules.materialDonations.productsList.product"
                                                )
                                            }}
                                        </label>
                                        <formulateInput
                                            :id="'description'"
                                            :name="'description'"
                                            type="vue-autosuggest"
                                            :placeholder="
                                                $t('common.crud.name')
                                            "
                                            @selected="
                                                setProduct($event, index)
                                            "
                                            @input="
                                                handleInputChange($event, index)
                                            "
                                            @updateProductOptions="
                                                handleOptionsChange(
                                                    $event,
                                                    index
                                                )
                                            "
                                            @update:input="
                                                manageSearchAfterInput(
                                                    $event,
                                                    index
                                                )
                                            "
                                        >
                                        </formulateInput>
                                    </b-col>
                                    <b-col cols="12" lg="2">
                                        <label class="d-inline d-lg-none">
                                            {{
                                                $t(
                                                    "modules.materialDonations.productsList.category"
                                                )
                                            }}
                                        </label>
                                        <formulateInput
                                            :id="'category'"
                                            name="category"
                                            :placeholder="
                                                $t('common.crud.select')
                                            "
                                            type="vue-select"
                                            validation="required"
                                            :options="
                                                productsCategories.map(
                                                    (el) => ({
                                                        value: el.id,
                                                        label: el.description,
                                                    })
                                                )
                                            "
                                            :disabled="disabled"
                                            @update:input="
                                                updateProductsProperty(
                                                    index,
                                                    $event,
                                                    'category'
                                                )
                                            "
                                        />
                                    </b-col>
                                    <b-col cols="12" lg="2">
                                        <label class="d-inline d-lg-none">
                                            {{
                                                $t(
                                                    "modules.materialDonations.productsList.type"
                                                )
                                            }}
                                        </label>
                                        <formulateInput
                                            :id="'type'"
                                            name="unitOfMeasurement"
                                            :placeholder="
                                                $t('common.crud.select')
                                            "
                                            type="vue-select"
                                            validation="required"
                                            :options="
                                                unitsOfMeasurement.map(
                                                    (el) => ({
                                                        value: el.id,
                                                        label: el.description,
                                                    })
                                                )
                                            "
                                            :disabled="disabled"
                                            @update:input="
                                                updateProductsProperty(
                                                    index,
                                                    $event,
                                                    'unitOfMeasurement'
                                                )
                                            "
                                        />
                                    </b-col>
                                    <b-col cols="12" lg="1">
                                        <label class="d-inline d-lg-none">
                                            {{
                                                $t(
                                                    "modules.materialDonations.productsList.quantity"
                                                )
                                            }}
                                        </label>
                                        <formulateInput
                                            :id="'quantity'"
                                            name="quantity"
                                            :placeholder="
                                                $t(
                                                    'modules.materialDonations.productsList.quantity'
                                                )
                                            "
                                            type="number"
                                            validation="required|max:10,length"
                                            :validationName="
                                                $t(
                                                    'modules.materialDonations.productsList.quantity'
                                                )
                                            "
                                            maxlength="10"
                                        />
                                    </b-col>
                                    <b-col cols="12" lg="1">
                                        <label class="d-inline d-lg-none">
                                            {{
                                                $t(
                                                    "modules.materialDonations.productsList.unitValue"
                                                )
                                            }}
                                        </label>
                                        <formulateInput
                                            :id="'unitValue'"
                                            name="unitValue"
                                            :placeholder="
                                                $t(
                                                    'modules.materialDonations.productsList.unitValue'
                                                )
                                            "
                                            type="number"
                                            validation="required|max:16,length"
                                            :validationName="
                                                $t(
                                                    'modules.materialDonations.productsList.unitValue'
                                                )
                                            "
                                            maxlength="16"
                                        />
                                    </b-col>
                                    <b-col cols="12" lg="1">
                                        <label class="d-inline d-lg-none">
                                            {{
                                                $t(
                                                    "modules.materialDonations.productsList.totalValue"
                                                )
                                            }}
                                        </label>
                                        <div>
                                            <span
                                                v-if="
                                                    validatesTotalValue(index)
                                                "
                                                class="form-control disabled"
                                            >
                                                {{
                                                    (
                                                        Math.round(
                                                            formData.products[
                                                                index
                                                            ].unitValue *
                                                                formData
                                                                    .products[
                                                                    index
                                                                ].quantity *
                                                                100
                                                        ) / 100
                                                    ).toFixed(2)
                                                }}
                                            </span>
                                        </div>
                                    </b-col>
                                </b-row>
                            </template>
                        </FormulateInput>
                        <b-row>
                            <b-col class="d-flex justify-content-center">
                                <formulateErrors />
                            </b-col>
                        </b-row>
                    </formulateForm>
                </b-col>
            </b-row>
        </div>
    </b-card-body>
</template>

<script>
import { BRow, BCol, BButton, BCardBody } from "bootstrap-vue"

import Ripple from "vue-ripple-directive"
import { heightTransition } from "@core/mixins/ui/transition"
import _ from "lodash"
import Api from "@/services/api"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BCardBody,
        BButton,
        BRow,
        BCol,
    },
    directives: {
        Ripple,
    },
    mixins: [heightTransition],
    props: {
        productsList: {
            required: false,
            default: () => [],
            type: Array,
        },
        productsCategories: {
            required: false,
            default: () => [],
            type: Array,
        },
        unitsOfMeasurement: {
            required: false,
            default: () => [],
            type: Array,
        },
        deliveryLocations: {
            required: false,
            default: () => [],
            type: Array,
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean,
        },
    },
    emits: ["updateProductsList"],
    data() {
        return {
            formData: {
                products: [],
            },
            formBlankItem: {
                description: "",
                category: "1",
                unitOfMeasurement: "1",
                quantity: "",
                unitValue: "",
                totalValue: "",
            },
            originalProductList: null,
            suggestedProducts: [],
            suggestedProductSelected: null,
            productsReponse: null,
            formErrors: {},
        }
    },
    computed: {
        products() {
            return this.formData.products
        },
    },
    mounted() {
        this.formData.products =
            this.productsList.length > 0
                ? this.productsList
                : [JSON.parse(JSON.stringify(this.formBlankItem))]

        this.originalProductList = this.productsList
    },
    methods: {
        validatesTotalValue(index) {
            return (
                _.get(this.formData.products[index], "unitValue") &&
                _.get(this.formData.products[index], "quantity") &&
                !Number.isNaN(
                    parseFloat(this.formData.products[index].unitValue)
                ) &&
                !Number.isNaN(
                    parseFloat(this.formData.products[index].quantity)
                )
            )
        },

        manageSearchAfterInput(text, index) {
            this.suggestedProductSelected = null
            const products = _.cloneDeep(this.formData.products)

            const product = {
                product: _.get(products[index], "description")
                    ? products[index].description
                    : "",
                category: _.get(products[index], "category")
                    ? products[index].category
                    : "",
                unitOfMeasurement: _.get(products[index], "unitOfMeasurement")
                    ? products[index].unitOfMeasurement
                    : "",
                quantity: _.get(products[index], "quantity")
                    ? products[index].quantity
                    : "",
                unitValue: _.get(products[index], "unitValue")
                    ? products[index].unitValue
                    : "",
                totalValue: _.get(products[index], "totalValue")
                    ? products[index].totalValue
                    : "",
                deliveryLocation: _.get(products[index], "deliveryLocation")
                    ? products[index].deliveryLocation
                    : "",
            }
            products[index] = { ...this.formBlankItem, ...product }
            products[index].description = text
            this.formData.products = products
        },

        updateProductsProperty(index, value, property) {
            const products = _.cloneDeep(this.formData.products)
            products[index][property] = value
            this.formData.products = products
        },
        getSuggestionValue(suggestion) {
            return suggestion.item.description
        },

        setProduct(suggestion, index) {
            const products = _.cloneDeep(this.formData.products)
            products[index] = {
                ...this.formBlankItem,
                ...suggestion.item,
                category: suggestion.item.category.id,
                unitOfMeasurement: suggestion.item.unitOfMeasurement.id,
            }
            this.suggestedProductSelected = suggestion.item

            this.formData.products = products
        },
        handleInputChange(suggestion, index) {
            this.$emit("updateProductsList", this.formData.products)
            this.setProductAutocomplete(suggestion, index)
        },
        handleOptionsChange(products, index) {
            if (!products || products.length === 0) return
            this.suggestedProducts = products
            if (products.length > 1) return
            const { description } = this.formData.products[0]
            this.setProductAutocomplete(description, index)
        },
        setProductAutocomplete(description, index) {
            const product = this.suggestedProducts.find(
                el =>
                    el.description.toLowerCase() === description.toLowerCase()
            )
            if (!product) return

            const products = _.cloneDeep(this.formData.products)
            products[index] = {
                ...product,
                category: product.category.id,
                unitOfMeasurement: product.unitOfMeasurement.id,
            }
            this.suggestedProductSelected = product

            this.formData.products = products
            this.$emit("updateProductsList", this.formData.products)
        },

        // eslint-disable-next-line consistent-return
        async dispatchProductsRequest() {
            try {
                const productsRequests = this.formData.products.map(el => {
                    if (_.get(el, "id") || _.get(el, "productId"))
                        return Api.update("products", {
                            id: _.get(el, "productId")
                                ? el.productId.toString()
                                : el.id.toString(),
                            description: el.description,
                            category: el.category
                                ? { type: "categories", id: el.category }
                                : null,
                            unitOfMeasurement: el.unitOfMeasurement
                                ? {
                                      type: "units-of-measurement",
                                      id: el.unitOfMeasurement,
                                  }
                                : null,
                        })

                    return Api.create("products", {
                        description: el.description,
                        category: el.category
                            ? { type: "categories", id: el.category }
                            : null,
                        unitOfMeasurement: el.unitOfMeasurement
                            ? {
                                  type: "units-of-measurement",
                                  id: el.unitOfMeasurement,
                              }
                            : null,
                    })
                })

                const results = await Promise.allSettled(productsRequests)

                this.formErrors = this.getResponseErrors(results)

                const errors = results.reduce((acc, result) => {
                    if (result.status === "rejected") {
                        acc.push(`${result.reason}`)
                    }
                    return acc
                }, [])
                if (errors.length > 0) {
                    return false
                }

                this.productsReponse = results
                    .filter(result => result.status === "fulfilled")
                    .map(result => result.value)

                return this.productsReponse
            } catch (e) {
                this.$formulate.handle(e, "productsForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        getResponseErrors(response) {
            return response.reduce((acc, error, index) => {
                if (error.status === "rejected" && error.reason) {
                    Object.entries(error.reason).forEach(([key, value]) => {
                        const errorKey = `${index}.${key}`
                        acc[errorKey] = value.detail
                    })
                }
                return acc
            }, {})
        },

        // eslint-disable-next-line consistent-return
        async dispatchRequest(materialDonation) {
            let results
            try {
                const attachRequest = this.formData.products.map(
                    (el, index) => {
                        const totalValue =
                            this.formData.products[index].quantity *
                            this.formData.products[index].unitValue
                        const quantity = parseFloat(
                            this.formData.products[index].quantity
                        )
                        const unitValue = parseFloat(
                            this.formData.products[index].unitValue
                        )

                        if (_.get(el, "productId"))
                            return Api.update("material-donation-items", {
                                id: el.id,
                                productId: parseFloat(el.productId),
                                materialDonationId: parseFloat(
                                    materialDonation.id
                                ),
                                quantity,
                                unitValue,
                                totalValue,
                            })

                        return Api.create("material-donation-items", {
                            productId: parseFloat(
                                this.productsReponse[index].data.id
                            ),
                            materialDonationId: parseFloat(materialDonation.id),
                            quantity,
                            unitValue,
                            totalValue,
                        })
                    }
                )

                const deletedProducts = this.originalProductList.filter(
                    product =>
                        !this.formData.products.find(p => product.id === p.id)
                )

                let deleteRequest = []
                if (deletedProducts)
                    deleteRequest = deletedProducts.map(el =>
                        Api.destroy("material-donation-items", el.id)
                    )

                results = await Promise.allSettled(
                    attachRequest.concat(deleteRequest)
                )

                this.formErrors = this.getResponseErrors(results)

                const errors = results.reduce((acc, result) => {
                    if (result.status === "rejected") {
                        acc.push(`${result.reason}`)
                    }
                    return acc
                }, [])
                if (errors.length > 0) {
                    return false
                }
            } catch (e) {
                this.$formulate.handle(e, "productsForm")
                this.$toast(toastMessages.getUnexpectedError())
            }

            return results
                .filter(result => result.status === "fulfilled")
                .map(result => result.value)
        },
    },
}
</script>
