<template>
    <formulateInput
        :label="label"
        :name="name"
        :placeholder="placeholder"
        type="vue-select-async"
        :validation="validation"
        :disabled="setDisabled"
        :apiData="{
            resource: 'people',
            fields: { people: 'name' },
            fieldsMapping: {
                label: 'name',
                value: 'id',
            },
        }"
        :options="options"
        @update:input="$emit('update:input', $event)"
        @update:options="$emit('update:options', $event)"
    />
</template>

<script>
export default {
    name: "SelectUser",
    props: {
        options: {
            required: false,
            type: Array,
            default() {
                return []
            },
        },
        label: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        validation: {
            type: String,
            required: false,
            default: "optional",
        },
        setDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
}
</script>

<style scoped></style>
