var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.modalId,"centered":"","hideFooter":"","headerClass":"header"},on:{"show":_vm.setAttributesDefaultValues},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t("modules.materialDonations.reopenDonation"))+" ")])]},proxy:true}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('div',[_c('div',{staticClass:"ml-2 mr-2 mt-1"},[_c('formulateForm',{attrs:{"name":"materialDonationReopenForm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
return [_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-avatar',{attrs:{"variant":"info","text":_vm.getNameInitials,"size":"5rem"}})],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"9"}},[_c('b-row',[(_vm.materialDonation.contact)?_c('h4',{staticClass:"text-truncate d-block"},[_vm._v(" "+_vm._s(_vm.materialDonation.contact.name)+" ")]):_vm._e()])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.materialDonationStatusOptions)?_c('formulateInput',{attrs:{"label":_vm.$t('common.status'),"name":"materialDonationStatus","placeholder":_vm.$t('common.status'),"type":"select","validation":"required|max:32,length","options":_vm.filterStatus}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('formulateInput',{attrs:{"label":_vm.$t(
                                    'modules.materialDonations.materialDonationStatusReason'
                                ),"placeholder":_vm.$t(
                                    'modules.materialDonations.materialDonationStatusReason'
                                ),"name":"materialDonationStatusReason","type":"textarea","maxlength":"255","rows":"7","validation":"required|max:255,length"},model:{value:(_vm.formData.materialDonationStatusReason),callback:function ($$v) {_vm.$set(_vm.formData, "materialDonationStatusReason", $$v)},expression:"formData.materialDonationStatusReason"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateErrors')],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('formulateInput',{attrs:{"disabled":hasErrors || _vm.isLoading,"label":_vm.isLoading
                                    ? _vm.$t('common.crud.updating')
                                    : _vm.$t('common.crud.update'),"inputClass":"btn btn-primary","type":"submit"},on:{"click":_vm.handleSubmit}})],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('FormulateInput',{attrs:{"label":_vm.$t('common.crud.close'),"inputClass":"btn btn-danger","type":"button"},on:{"click":_vm.hide}})],1)],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }