<template>
    <b-modal
        :id="modalId"
        v-model="active"
        centered
        hideFooter
        headerClass="header"
        @show="setAttributesDefaultValues"
    >
        <template #modal-title>
            <h5 class="text-white">
                {{ $t("modules.materialDonations.schedulingDonation") }}
            </h5>
        </template>
        <div>
            <div class="ml-2 mr-2 mt-1">
                <formulateForm
                    v-slot="{ hasErrors }"
                    v-model="formData"
                    name="materialDonationSchedulingForm"
                >
                    <b-row class="mb-2">
                        <b-col cols="12" md="3">
                            <b-avatar
                                variant="info"
                                :text="getNameInitials"
                                size="5rem"
                            ></b-avatar>
                        </b-col>

                        <b-col cols="12" md="9" class="mt-2">
                            <b-row>
                                <h4
                                    v-if="materialDonation.contact"
                                    class="text-truncate d-block"
                                >
                                    {{ materialDonation.contact.name }}
                                </h4>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12">
                            <formulateInput
                                v-if="materialDonationStatusOptions"
                                :label="$t('common.status')"
                                name="materialDonationStatus"
                                :placeholder="$t('common.status')"
                                type="select"
                                validation="required|max:32,length"
                                :options="filterStatus"
                            />
                        </b-col>

                        <b-col cols="6">
                            <formulateInput
                                v-model="formData.schedulingDate"
                                :label="
                                    $t(
                                        'modules.materialDonations.schedulingDate'
                                    )
                                "
                                :placeholder="
                                    $t(
                                        'modules.materialDonations.schedulingDate'
                                    )
                                "
                                name="schedulingDate"
                                type="custom-date"
                                validation="required|date:YYYY-MM-DD"
                                @update:date="formData.schedulingDate = $event"
                            >
                            </formulateInput>
                        </b-col>

                        <b-col cols="6">
                            <formulateInput
                                v-model="formData.schedulingTime"
                                :label="$t('modules.materialDonations.time')"
                                placeholder="HH:MM"
                                maxlength="5"
                                name="schedulingTime"
                                type="text"
                                :validation="[
                                    [
                                        'matches',
                                        '/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/',
                                    ],
                                    ['required'],
                                ]"
                                @input="timeMask"
                            >
                            </formulateInput>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <formulateErrors />
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col class="d-flex justify-content-center">
                            <formulateInput
                                :disabled="hasErrors || isLoading"
                                :label="
                                    isLoading
                                        ? $t('common.crud.updating')
                                        : $t('common.crud.update')
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                                @click="handleSubmit"
                            />
                        </b-col>

                        <b-col class="d-flex justify-content-center">
                            <FormulateInput
                                :label="$t('common.crud.close')"
                                inputClass="btn btn-danger"
                                type="button"
                                @click="hide"
                            />
                        </b-col>
                    </b-row>
                </formulateForm>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { BRow, BCol, BModal, BAvatar } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import _ from "lodash"

export default {
    name: "MaterialDonationSchedulingModal",
    components: {
        BAvatar,
        BRow,
        BCol,
        BModal,
    },
    props: {
        materialDonation: {
            type: Object,
            required: true,
        },
        materialDonationStatusOptions: {
            type: Array,
            required: true,
        },
        modalId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            active: false,
            formData: {
                materialDonationStatus: "",
                schedulingDate: "",
                schedulingTime: "",
            },
            isLoading: false,
        }
    },
    computed: {
        schedulingTime() {
            return this.formData.schedulingTime
        },
        getNameInitials() {
            if (!_.get(this.materialDonation, "contact")) return ""

            const split = this.materialDonation.contact.name.split(" ", 2)
            if (split.length < 2) {
                return (
                    this.materialDonation.contact.name[0] +
                    this.materialDonation.contact.name[1]
                )
            }
            const first = split[0]
            const second = split[1]
            return first.charAt(0) + second.charAt(0)
        },
        filterStatus() {
            let filteredStatuses = []

            const filterStatus = ["scheduled"]

            filteredStatuses = this.materialDonationStatusOptions
                .filter(el => filterStatus.includes(el.systemDefaultStatus))
                .map(el => ({
                    value: el.id,
                    label: el.description,
                }))

            return filteredStatuses
        },
    },
    watch: {
        schedulingTime(newVal) {
            if (!newVal) return
            const numericValue = newVal.replace(/\D/g, "")
            if (numericValue.length <= 2) {
                this.formData.schedulingTime = numericValue
            } else {
                this.formData.schedulingTime = `${numericValue.slice(
                    0,
                    2
                )}:${numericValue.slice(2)}`
            }
        },
    },
    created() {
        this.isLoading = false
    },
    methods: {
        timeMask(event) {
            if (!event) return

            const inputValue = event.replace(/\D/g, "")

            if (inputValue.length <= 2) {
                this.formData.schedulingTime = inputValue
            } else {
                this.formData.schedulingTime = `${inputValue.slice(
                    0,
                    2
                )}:${inputValue.slice(2)}`
            }
        },
        show() {
            this.active = true
        },
        hide() {
            this.active = false
            this.setAttributesDefaultValues()
        },

        setAttributesDefaultValues() {
            [
                this.formData.materialDonationStatus,
            ] = this.filterStatus[0].value.toString()
            this.formData.schedulingDate = ""
            this.formData.schedulingTime = ""
        },

        async handleSubmit() {
            try {
                this.isLoading = true
                const schedulingDateTime = `${this.formData.schedulingDate} ${this.formData.schedulingTime}`
                const payload = {
                    data: {
                        type: "material-donations",
                        id: String(this.materialDonation.id),
                        attributes: {
                            schedulingDate: schedulingDateTime,
                        },
                    },
                }
                const response = await Api.axios.patch(
                    `/api/v1/material-donations/${this.materialDonation.id}/-actions/schedule`,
                    payload
                )

                this.$toast(toastMessages.getSuccessMessage())
                this.hide()
                this.$emit("updateMaterialDonationStatus", response.data)
            } catch (e) {
                this.$formulate.handle(e, "materialDonationSchedulingForm")
                this.createdCompany = null
                this.$toast(toastMessages.getUnexpectedError())
                this.$bvModal.hide(this.modalId)
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>

<style lang="scss">
.header {
    background-color: #519bd6 !important;
    width: 100%;
    color: #ffffff;
    position: relative;
}
.text-white {
    color: #ffffff;
}
</style>
