<template>
    <div>
        <div>
            <noteForm
                id="add-note"
                :resourceData="resourceData"
                :note="selectedNote"
                :resourceType="resourceType"
                @attachOrUpdateNote="attachOrUpdateNote"
                @hide="selectedNote = null"
            >
            </noteForm>
        </div>
        <b-card>
            <b-navbar class="mb-2">
                <b-navbar-brand tag="h3">
                    {{ $tc("modules.notes.description", 1) }}

                    <a
                        v-b-modal.add-note
                        class="pl-2 a-link"
                        @click="selectedNote = null"
                    >
                        +
                        <p class="under-blue-text d-inline">
                            {{
                                $t("common.crud.addNew", {
                                    resource: $tc(
                                        "modules.notes.description",
                                        1
                                    ).toLowerCase(),
                                })
                            }}
                        </p>
                    </a>
                </b-navbar-brand>
            </b-navbar>

            <b-row class="">
                <b-col v-if="notes.length === 0">
                    <h4 class="text-center mb-2">
                        {{
                            $t("common.noResource", {
                                resource: $t("common.notes").toLowerCase(),
                            })
                        }}
                    </h4>
                </b-col>
                <b-col
                    v-for="(note, index) in notes"
                    v-else
                    :key="index"
                    cols="12"
                    md="6"
                >
                    <b-card>
                        <b-card-body class="border-note-card">
                            <b-card-title class="d-block pr-2">
                                {{ $tc("modules.notes.description", 1) }}
                                <feather-icon
                                    icon="Trash2Icon"
                                    class="float-right mr-2"
                                    size="20"
                                    style="cursor: pointer"
                                    @click="onDeleteClick(note, index)"
                                />
                                <feather-icon
                                    v-b-modal.add-note
                                    class="float-right"
                                    icon="Edit2Icon"
                                    size="20"
                                    style="cursor: pointer"
                                    @click="onEditClick(note)"
                                />
                                <b-row>
                                    <b-col>
                                        <span class="float-right">
                                            <span class="d-inline pl-1">
                                                <span
                                                    v-if="
                                                        note.updatedAt !==
                                                        note.createdAt
                                                    "
                                                    class="font-small-3 pr-1 d-block"
                                                    style="color: #b9b9c3"
                                                >
                                                    {{
                                                        $t(
                                                            "common.crud.updatedAt"
                                                        )
                                                    }}
                                                    :
                                                    {{
                                                        formatDateTime(
                                                            note.updatedAt
                                                        )
                                                    }}
                                                    <span
                                                        v-if="note.editor"
                                                        class="d-inline pl-1 font-small-3"
                                                    >
                                                        {{
                                                            $t(
                                                                "common.crud.updatedBy"
                                                            )
                                                        }}
                                                        <span>
                                                            {{
                                                                note.editor.name
                                                            }}
                                                        </span>
                                                    </span>
                                                </span>
                                                <span
                                                    class="font-small-3 pr-1 d-block"
                                                    style="color: #b9b9c3"
                                                >
                                                    {{
                                                        $t(
                                                            "common.crud.createdAt"
                                                        )
                                                    }}
                                                    :
                                                    {{
                                                        formatDateTime(
                                                            note.createdAt
                                                        )
                                                    }}
                                                    <span
                                                        class="d-inline pl-1 font-small-3"
                                                    >
                                                        {{
                                                            $t(
                                                                "common.crud.createdBy"
                                                            )
                                                        }}
                                                        <span
                                                            v-if="note.creator"
                                                        >
                                                            {{
                                                                note.creator
                                                                    .name
                                                            }}
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </b-col>
                                </b-row>
                            </b-card-title>
                            <b-card-text class="pt-2">
                                {{ note.description }}
                            </b-card-text>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCol,
    BNavbar,
    BNavbarBrand,
    BRow,
    BCardBody,
    BCardTitle,
    BCardText,
} from "bootstrap-vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import NoteForm from "@/views/components/utilis-spa/note/AttachNoteModal.vue"

import Api from "@/services/api/index"
import { mapMutations } from "vuex"

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BNavbar,
        BNavbarBrand,
        BCardBody,
        BCardTitle,
        BCardText,
        NoteForm,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            isLoading: true,
            service: Api,
            notes: [],
            selectedNote: null,
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                Ativo: "light-success",
                Inativo: "light-danger",
            }

            return status => statusColor[status]
        },
    },

    mounted() {
        this.notes = this.resourceData.notes
    },
    methods: {
        ...mapMutations({
            SET_PERSON: "people/SET_PERSON",
            UPDATE_PERSON_PROPERTY: "people/UPDATE_PERSON_PROPERTY",
            UPDATE_COMPANY_PROPERTY: "companies/UPDATE_COMPANY_PROPERTY",
        }),

        formatDateTime(date) {
            // return date.format('L LT')
            // return this.$d(date, 'short')
            if (date == null) return

            // eslint-disable-next-line consistent-return
            return this.$d(new Date(date), "dateTime")
        },

        attachOrUpdateNote(note) {
            if (this.selectedNote === null) {
                this.notes.push(note)
            } else {
                const index = this.notes.findIndex(
                    element => element.id === note.id
                )
                this.notes[index] = note
            }
            this.$emit("attachNote", this.notes)
        },

        onEditClick(params) {
            this.selectedNote = params
        },
        async onDeleteClick(params, index) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one(`${this.resourceType}`, this.resourceData.id)
                    .relationships("notes")
                    .destroy([
                        {
                            type: "notes",
                            id: params.id,
                        },
                    ])

                this.notes.splice(index, 1)
                this.updateVuexResourceStateProperty("notes", this.notes)

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style scoped>
.a-link {
    color: rgb(81, 155, 214) !important;
    font-size: 1.5rem;
}

.border-note-card {
    border-left: solid #ebe9f1;
}
</style>
