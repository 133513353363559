<template>
    <div>
        <material-donation-create
            v-if="$can('create', 'material-donations')"
            :donationContact="donationContact"
            :donationContactIsCompany="donationContactIsCompany"
            :donorAddressesOptions="
                donationContact && Object.hasOwn(donationContact, 'addresses')
                    ? donationContact.addresses
                    : []
            "
            @materialDonationCreated="getMaterialDonations"
        >
        </material-donation-create>

        <material-donation-edit
            v-if="materialDonationSelected"
            :materialDonation="materialDonationSelected"
            :donationContact="donationContact"
            :donationContactIsCompany="donationContactIsCompany"
            @materialDonationUpdated="getMaterialDonations"
        >
        </material-donation-edit>

        <material-donation-view
            v-if="materialDonationSelected"
            :materialDonation="materialDonationSelected"
            @materialDonationUpdated="getMaterialDonations"
        >
        </material-donation-view>

        <material-donation-confirmation-modal
            :materialDonation="materialDonationSelected"
            modalId="confirmationModal"
            :materialDonationStatusOptions="materialDonationStatusOptions"
            @updateMaterialDonationStatus="getMaterialDonations"
        >
        </material-donation-confirmation-modal>

        <material-donation-reopen-modal
            :materialDonation="materialDonationSelected"
            modalId="reopenModal"
            :materialDonationStatusOptions="materialDonationStatusOptions"
            @updateMaterialDonationStatus="getMaterialDonations"
        >
        </material-donation-reopen-modal>

        <material-donation-scheduling-modal
            :materialDonation="materialDonationSelected"
            modalId="schedulingModal"
            :materialDonationStatusOptions="materialDonationStatusOptions"
            @updateMaterialDonationStatus="getMaterialDonations"
        >
        </material-donation-scheduling-modal>

        <material-donation-collection-list-modal>
        </material-donation-collection-list-modal>

        <material-donation-collections-modal>
        </material-donation-collections-modal>

        <b-card>
            <datatable-header
                :title="$tc('modules.materialDonations.description', 2)"
                :genericSearchEnabled="true"
                :searchTermPlaceHolder="$t('common.search')"
                @filterUpdated="onFilterUpdate"
            >
                <template #buttons>
                    <b-button
                        class="ml-2"
                        variant="primary"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'create-material-donations-modal'
                            )
                        "
                    >
                        {{
                            $t("common.crud.addNew", {
                                resource: $tc(
                                    "modules.materialDonations.description",
                                    1
                                ).toLowerCase(),
                            }).toString()
                        }}
                    </b-button>

                    <b-button
                        class="ml-2"
                        variant="success"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'material-donation-collection-list-modal'
                            )
                        "
                    >
                        {{ $t("modules.materialDonations.collectionList") }}
                    </b-button>

                    <b-button
                        class="ml-2"
                        variant="primary"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'material-donation-collection-modal'
                            )
                        "
                    >
                        {{ $t("modules.materialDonations.collectionList") }}
                    </b-button>

                    <b-button
                        class="ml-2"
                        variant="outline-primary"
                        @click="exportXls"
                    >
                        <feather-icon
                            :id="`download-button-icon`"
                            size="16"
                            icon="DownloadIcon"
                            class="cursor-pointer mr-1"
                        />
                        {{ $t("common.export") }}
                        <feather-icon
                            :id="`download-button-icon`"
                            size="16"
                            icon="ChevronDownIcon"
                            class="cursor-pointer ml-1"
                        />
                    </b-button>
                </template>

                <template #advancedSearch="{ expanded, onFilterChange }">
                    <DatatableFilter
                        :availableDatatableFilters="availableDatatableFilters"
                        :expanded="expanded"
                        @onFilterChange="onFilterChange"
                    ></DatatableFilter>
                </template>
            </datatable-header>
            <datatable
                :columns="columns"
                :isLoading="isLoading"
                :paginationOptions="{
                    enabled: true,
                    mode: 'pages',
                    perPage: dataTableOptions.pagination.perPage,
                }"
                :rows="materialDonationList ? materialDonationList : rows"
                :rtl="direction"
                :searchOptions="{
                    enabled: true,
                    externalQuery: searchTerm,
                }"
                :totalRows="dataTableOptions.pagination.totalRows"
                mode="remote"
                theme="my-theme"
                @on-page-change="handleChangePage"
                @onPageSizeChange="handlePageSizeChange"
            >
                <template #table-row="props">
                    <span v-if="props.column.field === 'action'">
                        <a
                            v-if="props.row.action"
                            class="text-custom-blue"
                            role="button"
                            @mousedown="
                                openInNewTab(
                                    $event,
                                    'view-actions',
                                    props.row.action.id
                                )
                            "
                            @click="
                                $router.push({
                                    name: 'view-actions',
                                    params: { id: props.row.action.id },
                                })
                            "
                        >
                            {{ props.row.action.title }}
                        </a>
                    </span>

                    <span v-else-if="props.column.field === 'status'">
                        <b-badge
                            v-if="props.row.status"
                            :variant="
                                statusVariant(
                                    props.row.status.systemDefaultStatus
                                )
                            "
                        >
                            {{ props.row.status.description }}
                        </b-badge>
                    </span>

                    <span v-else-if="props.column.field === 'contact'">
                        <a
                            v-if="props.row.contact"
                            class="font-weight-bold text-custom-blue"
                            role="button"
                            @mousedown="
                                props.row.contact.type === 'people'
                                    ? openInNewTab(
                                          $event,
                                          'view-people',
                                          props.row.action.id
                                      )
                                    : openInNewTab(
                                          $event,
                                          'view-companies',
                                          props.row.action.id
                                      )
                            "
                            @click="
                                props.row.contact.type === 'people'
                                    ? $router.push({
                                          name: 'view-people',
                                          params: { id: props.row.contact.id },
                                      })
                                    : $router.push({
                                          name: 'view-companies',
                                          params: { id: props.row.contact.id },
                                      })
                            "
                        >
                            {{ props.row.contact.name }}
                        </a>
                    </span>

                    <span v-else-if="props.column.field === 'products'">
                        <span v-if="props.row.products">
                            R$
                            {{
                                formatFloat(
                                    props.row.products.reduce(
                                        (total, product) =>
                                            parseFloat(total) +
                                            parseFloat(product.totalValue),
                                        0
                                    )
                                )
                            }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'editor'">
                        <span
                            v-if="props.row.editor"
                            class="font-weight-bold text-custom-blue"
                            role="button"
                            @click="
                                $router.push({
                                    name: 'view-users',
                                    params: { id: props.row.editor.id },
                                })
                            "
                        >
                            {{ props.row.editor.name }}
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'actions'">
                        <feather-icon
                            :id="`form-item-settings-icon-${props.index}`"
                            size="16"
                            icon="EditIcon"
                            class="cursor-pointer ml-2"
                        />
                        <b-popover
                            :ref="`form-item-settings-popover-${props.index}`"
                            :target="`form-item-settings-icon-${props.index}`"
                            triggers="hover focus"
                            placement="top"
                        >
                            <b-button-group>
                                <feather-icon
                                    v-if="
                                        $can('edit', 'material-donations') &&
                                        props.row.status &&
                                        Object.hasOwn(
                                            props.row.status,
                                            'systemDefaultStatus'
                                        ) &&
                                        [
                                            'open',
                                            'scheduled',
                                            'reopened',
                                        ].includes(
                                            props.row.status.systemDefaultStatus
                                        )
                                    "
                                    :id="`form-item-settings-icon-${props.index}`"
                                    v-b-tooltip.hover
                                    :title="$t('common.crud.edit')"
                                    size="24"
                                    icon="Edit2Icon"
                                    class="cursor-pointer ml-1 mr-1"
                                    @click="
                                        openModal(
                                            props.row,
                                            'edit-material-donations-modal'
                                        )
                                    "
                                />
                                <feather-icon
                                    v-else
                                    :id="`form-item-settings-icon-${props.index}`"
                                    v-b-tooltip.hover
                                    :title="$t('common.crud.view')"
                                    size="24"
                                    icon="EyeIcon"
                                    class="cursor-pointer ml-1 mr-1"
                                    @click="
                                        openModal(
                                            props.row,
                                            'view-material-donations-modal'
                                        )
                                    "
                                />
                                <feather-icon
                                    v-if="
                                        props.row.status &&
                                        Object.hasOwn(
                                            props.row.status,
                                            'systemDefaultStatus'
                                        ) &&
                                        [
                                            'open',
                                            'scheduled',
                                            'reopened',
                                        ].includes(
                                            props.row.status.systemDefaultStatus
                                        )
                                    "
                                    :id="`form-item-settings-icon-${props.index}`"
                                    v-b-tooltip.hover
                                    :title="
                                        $t(
                                            'modules.materialDonations.schedulingDonation'
                                        )
                                    "
                                    size="24"
                                    icon="ClockIcon"
                                    class="cursor-pointer ml-1 mr-1"
                                    @click="
                                        openModal(props.row, 'schedulingModal')
                                    "
                                />
                                <feather-icon
                                    v-if="
                                        props.row.status &&
                                        Object.hasOwn(
                                            props.row.status,
                                            'systemDefaultStatus'
                                        ) &&
                                        [
                                            'open',
                                            'scheduled',
                                            'reopened',
                                        ].includes(
                                            props.row.status.systemDefaultStatus
                                        )
                                    "
                                    :id="`form-item-settings-icon-${props.index}`"
                                    v-b-tooltip.hover
                                    :title="
                                        $t(
                                            'modules.materialDonations.confirmDonation'
                                        )
                                    "
                                    size="24"
                                    icon="CheckSquareIcon"
                                    class="cursor-pointer ml-1 mr-1"
                                    @click="
                                        openModal(
                                            props.row,
                                            'confirmationModal'
                                        )
                                    "
                                />
                                <feather-icon
                                    v-if="
                                        props.row.status &&
                                        Object.hasOwn(
                                            props.row.status,
                                            'systemDefaultStatus'
                                        ) &&
                                        [
                                            'confirmed',
                                            'cancelled',
                                            'rejected',
                                        ].includes(
                                            props.row.status.systemDefaultStatus
                                        )
                                    "
                                    :id="`form-item-settings-icon-${props.index}`"
                                    v-b-tooltip.hover
                                    :title="
                                        $t(
                                            'modules.materialDonations.reopenDonation'
                                        )
                                    "
                                    size="24"
                                    icon="PackageIcon"
                                    class="cursor-pointer ml-1 mr-1"
                                    @click="openModal(props.row, 'reopenModal')"
                                />
                                <feather-icon
                                    v-if="
                                        $can(
                                            'print-receipt',
                                            'material-donations'
                                        ) &&
                                        Object.hasOwn(
                                            props.row.status,
                                            'systemDefaultStatus'
                                        ) &&
                                        !['cancelled', 'rejected'].includes(
                                            props.row.status.systemDefaultStatus
                                        )
                                    "
                                    :id="`form-item-settings-icon-${props.index}`"
                                    v-b-tooltip.hover
                                    :title="
                                        $t(
                                            'modules.materialDonations.donationReceipt'
                                        )
                                    "
                                    size="24"
                                    icon="ClipboardIcon"
                                    class="cursor-pointer ml-1 mr-1"
                                    @click="downloadReceipt(props.row.id)"
                                />
                            </b-button-group>
                        </b-popover>
                    </span>

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </datatable>
        </b-card>
    </div>
</template>

<script>
import { BBadge, BCard, BPopover, BButtonGroup, BButton } from "bootstrap-vue"
import store from "@/store/index"
import Api from "@/services/api/index"
import Datatable from "@/views/components/utilis-spa/datatable/Datatable.vue"
import DatatableFilter from "@/views/components/utilis-spa/datatable/DatatableFilter.vue"
import DatatableFilterUtils from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import MaterialDonationCreate from "@/views/app/material-donations/MaterialDonationCreate.vue"
import MaterialDonationEdit from "@/views/app/material-donations/MaterialDonationEdit.vue"
import MaterialDonationView from "@/views/app/material-donations/MaterialDonationView.vue"
import DatatableHeader from "@/views/components/utilis-spa/datatable/DatatableHeader.vue"
import MaterialDonationCollectionListModal from "@/views/app/material-donations/MaterialDonationCollectionListModal.vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import MaterialDonationSchedulingModal from "./MaterialDonationSchedulingModal.vue"
// eslint-disable-next-line import/no-duplicates
import MaterialDonationConfirmationModal from "./MaterialDonationConfirmationModal.vue"
// eslint-disable-next-line import/no-duplicates
import MaterialDonationReopenModal from "./MaterialDonationReopenModal.vue"
import MaterialDonationCollectionsModal from "./MaterialDonationCollectionsModal.vue"

export default {
    components: {
        DatatableHeader,
        BButton,
        BPopover,
        BCard,
        BButtonGroup,
        Datatable,
        BBadge,
        DatatableFilter,
        MaterialDonationConfirmationModal,
        MaterialDonationSchedulingModal,
        MaterialDonationReopenModal,
        MaterialDonationCreate,
        MaterialDonationEdit,
        MaterialDonationView,
        MaterialDonationCollectionListModal,
        MaterialDonationCollectionsModal,
    },
    props: {
        materialDonationList: {
            required: false,
            default: () => null,
            type: Array,
        },
        donationContact: {
            required: false,
            type: Object,
            default: () => {},
        },
        donationContactIsCompany: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dataTableOptions: {
                pagination: {
                    perPage: 5,
                    totalRows: 0,
                },
            },
            materialDonationStatusOptions: [],
            productCategoriesOptions: [],
            unitsOfMeasurementOptions: [],
            isLoading: true,
            dir: false,
            isFilterExpanded: false,
            materialDonationSelected: {},
            columns: [
                {
                    label: this.$t("common.status"),
                    field: "status",
                },
                {
                    label: this.$t("modules.materialDonations.date"),
                    field: "schedulingDate",
                    formatFn: this.formatDate,
                },
                {
                    label: this.$t("modules.materialDonations.donor"),
                    field: "contact",
                },
                {
                    label: this.$t("modules.materialDonations.action"),
                    field: "action",
                },
                {
                    label: this.$t("modules.materialDonations.id"),
                    field: "id",
                },
                {
                    label: this.$t("modules.materialDonations.emission"),
                    field: "createdAt",
                    formatFn: date =>
                        date
                            ? this.$moment(date).format(
                                  this.$t("customFormats.date")
                              )
                            : "",
                },
                {
                    label: this.$t("modules.materialDonations.scheduled"),
                    field: "schedulingDate",
                    formatFn: date =>
                        date
                            ? this.$moment(date).format(
                                  this.$t("customFormats.dateTime")
                              )
                            : "",
                },
                {
                    label: this.$t("modules.materialDonations.withdrawalDate"),
                    field: "withdrawalDate",
                    formatFn: date =>
                        date
                            ? this.$moment(date).format(
                                  this.$t("customFormats.dateTime")
                              )
                            : "",
                },
                {
                    label: this.$t("modules.materialDonations.value"),
                    field: "products",
                },
                {
                    label: this.$t("modules.materialDonations.user"),
                    field: "editor",
                },
                {
                    label: this.$t("common.crud.actions"),
                    field: "actions",
                },
            ],
            rows: [],
            searchTerm: "",
            availableDatatableFilters: {},
            apiFilter: null,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },

        statusVariant() {
            const statusColor = {
                reopened: "light-primary",
                rejected: "light-danger",
                cancelled: "light-danger",
                confirmed: "light-success",
                scheduled: "light-warning",
                open: "light-primary",
            }

            return status => statusColor[status]
        },
    },
    created() {
        this.getMaterialDonations()
        this.getAvailableFilters()
    },
    methods: {
        async downloadReceipt(id) {
            try {
                const response = await Api.axios.get(
                    `/api/v1/material-donations/-actions/export-print-receipt-list?id=${id}`,
                    { responseType: "blob" } // importante
                )

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute(
                    "download",
                    `relatorio-formulários-coleta-doacoes-material-${id}.pdf`
                )
                document.body.appendChild(link)
                link.click()

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        serializeFilter(filter, parentKey = "") {
            const parts = []

            Object.entries(filter).forEach(([key, value]) => {
                const fullKey = parentKey
                    ? `${parentKey}[${key}]`
                    : `filter[${key}]`

                if (typeof value === "object" && !Array.isArray(value)) {
                    parts.push(this.serializeFilter(value, fullKey))
                } else if (Array.isArray(value)) {
                    value.forEach(val => {
                        parts.push(`${fullKey}[]=${encodeURIComponent(val)}`)
                    })
                } else {
                    parts.push(`${fullKey}=${encodeURIComponent(value)}`)
                }
            })

            return parts.join("&")
        },
        async exportXls() {
            try {
                const serializedFilter = this.apiFilter
                    ? this.serializeFilter(this.apiFilter)
                    : ""

                const response = await Api.axios.get(
                    `/api/v1/material-donations/-actions/export-xls?${serializedFilter}`,
                    {
                        responseType: "blob",
                    }
                )

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute("download", `lista-doacoes-materiais.xlsx`)
                document.body.appendChild(link)
                link.click()

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        openModal(materialDonation, modalId) {
            this.materialDonationSelected = materialDonation
            setTimeout(() => {
                this.$root.$emit(
                    // eslint-disable-next-line vue/custom-event-name-casing
                    "bv::toggle::modal",
                    modalId
                )
            }, 300) // delay to prevent erro on prop when opening modal
        },
        formatFloat(value) {
            return (Math.round(value * 100) / 100).toFixed(2)
        },
        formatDate(date) {
            return date
                ? this.$moment(date).format(this.$t("customFormats.date"))
                : ""
        },
        handleChangePage(page) {
            this.isLoading = true
            this.getMaterialDonations({ selectedPage: page.currentPage })
        },
        handlePageSizeChange(pageSize) {
            this.dataTableOptions.pagination.perPage = pageSize
            this.getMaterialDonations()
        },
        async getMaterialDonations(options = {}) {
            try {
                if (this.materialDonationList) {
                    this.$emit("getRelatedMaterialDonations")
                    return
                }

                this.isLoading = true
                const { pagination: paginationOptions } = this.dataTableOptions
                const { selectedPage } = options

                const donationResponse = await Api.findAll(
                    "material-donations",
                    {
                        // RELATED RESOURCES
                        include:
                            "action,status,contact,withdrawalAddress,deliveryLocation,editor," +
                            "products,products.category,products.unitOfMeasurement",

                        // SPARSE FIELDSETS
                        fields: {
                            actions: "title",
                            "material-donation-statuses":
                                "description,systemDefaultStatus",
                            products: "description,category,unitOfMeasurement",
                            "delivery-locations": "description",
                            addresses:
                                "postalCode,isMainAddress,number,complement,description,completeAddress,street," +
                                "state,locality,neighborhood,addressClassification",
                            people: "name",
                        },

                        // FILTERS
                        filter: this.apiFilter,

                        // PAGINATION
                        page: {
                            size: paginationOptions.perPage,
                            number: selectedPage || 1,
                        },
                    }
                )

                const donationsIdFilter = donationResponse.data
                    .map(donation => donation.id)
                    .join(",")

                const materialDonationItemResponse = await Api.findAll(
                    "material-donation-items",
                    {
                        filter: {
                            materialDonationId: donationsIdFilter,
                        },
                        // SPARSE FIELDSETS
                        fields: {
                            "material-donation-items":
                                "productId,materialDonationId,quantity,unitValue,totalValue",
                        },
                    }
                )

                this.rows = donationResponse.data.map(donation => {
                    const products = donation.products.map(product => {
                        const item = materialDonationItemResponse.data.find(
                            materialDonationItem =>
                                parseInt(product.id, 10) ===
                                materialDonationItem.productId
                        )
                        const index = materialDonationItemResponse.data.indexOf(
                            item
                        )
                        materialDonationItemResponse.data.splice(index, 1)
                        return { ...product, ...item }
                    })
                    return { ...donation, ...{ products } }
                }, this)

                const paginationMetaData = donationResponse.meta.page
                this.dataTableOptions.pagination.totalRows =
                    paginationMetaData.total
            } finally {
                this.isLoading = false
            }
        },
        async getAvailableFilters() {
            const statusResponse = await Api.findAll(
                "material-donation-statuses",
                {
                    fields: {
                        "material-donation-statuses":
                            "description,systemDefaultStatus",
                    },
                }
            )

            this.materialDonationStatusOptions = statusResponse.data

            this.availableDatatableFilters.status = DatatableFilterUtils.createFilter.list(
                "status",
                statusResponse.data.map(status => ({
                    value: status.id,
                    label: status.description,
                })),
                {
                    filterAttribute: "id",
                    label: this.$t("common.status"),
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$t("common.status").toLowerCase(),
                    }),
                }
            )

            this.availableDatatableFilters.action = DatatableFilterUtils.createFilter.searchAsync(
                "action",
                {
                    label: this.$tc("modules.actions.description", 1),
                    placeholder: this.$t("common.selectOneOrMany", {
                        resource: this.$tc(
                            "modules.actions.description",
                            1
                        ).toLowerCase(),
                    }),
                    filterAttribute: "id",
                },
                {
                    startDate: {
                        name: this.$t("common.crud.startDate"),
                        apiData: {
                            resource: "actions",
                            fields: { actions: "title" },
                            fieldsMapping: {
                                label: "title",
                                value: "id",
                            },
                        },
                    },
                }
            )

            this.availableDatatableFilters.createdAt = DatatableFilterUtils.createFilter.dateRange(
                "createdAt",
                {
                    startDate: {
                        label: this.$t("common.crud.startDate"),
                        placeholder: this.$t("customFormats.date"),
                    },
                    endDate: {
                        label: this.$t("common.crud.endDate"),
                        placeholder: this.$t("customFormats.date"),
                    },
                }
            )
        },
        openInNewTab(event, routeName, id) {
            if (event.button === 1) {
                const routeData = this.$router.resolve({
                    name: routeName,
                    params: { id },
                })
                window.open(routeData.href, "_blank")
            }
        },
        onFilterUpdate(updatedFilter) {
            this.apiFilter = DatatableFilterUtils.serializer.datatableFilterToJsonApi(
                updatedFilter
            )
            this.getMaterialDonations()
        },
    },
}
</script>
