<template>
    <b-modal
        id="material-donation-collection-modal"
        v-model="active"
        centered
        hideFooter
        headerClass="modal-custom-header"
        @show="show"
    >
        <template #modal-title>
            <h5 class="text-white">
                {{ $t("modules.materialDonations.collectionForm") }}
            </h5>
        </template>

        <template #default>
            <div>
                <div class="ml-2 mr-2 mt-1">
                    <formulateForm
                        v-slot="{ hasErrors }"
                        v-model="formData"
                        name="CollectionListForm"
                    >
                        <b-row>
                            <b-col cols="12">
                                <label class="text-bold">
                                    {{ $t("common.crud.description") }}:</label
                                >
                                <span class="d-block">
                                    {{
                                        $t(
                                            "modules.materialDonations.collectionFormInfo"
                                        )
                                    }}
                                </span>
                            </b-col>
                        </b-row>

                        <b-row class="mt-2">
                            <b-col cols="12">
                                <span class="d-block">
                                    {{ $t("common.parameters") }}
                                </span>
                            </b-col>
                            <b-col cols="12" class="mt-1">
                                <formulateInput
                                    v-model="formData.startDate"
                                    :label="$t('common.crud.startDate')"
                                    :placeholder="$t('common.crud.startDate')"
                                    name="startDate"
                                    type="custom-date"
                                    validation="required|date:YYYY-MM-DD"
                                    @update:date="formData.startDate = $event"
                                >
                                </formulateInput>
                            </b-col>
                            <b-col cols="12">
                                <formulateInput
                                    v-model="formData.endDate"
                                    :label="$t('common.crud.endDate')"
                                    :placeholder="$t('common.crud.endDate')"
                                    name="endDate"
                                    type="custom-date"
                                    validation="required|date:YYYY-MM-DD"
                                    @update:date="formData.endDate = $event"
                                >
                                </formulateInput>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col class="d-flex justify-content-center">
                                <formulateErrors />
                            </b-col>
                        </b-row>

                        <b-row class="mt-2">
                            <b-col class="d-flex justify-content-center">
                                <formulateInput
                                    :disabled="hasErrors"
                                    :label="$t('common.crud.generate')"
                                    inputClass="btn btn-primary"
                                    type="submit"
                                    @click="handleSubmit"
                                />
                            </b-col>

                            <b-col class="d-flex justify-content-center">
                                <FormulateInput
                                    :label="$t('common.crud.close')"
                                    inputClass="btn btn-danger"
                                    type="button"
                                    @click="hide"
                                />
                            </b-col>
                        </b-row>
                    </formulateForm>
                </div>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { BRow, BCol, BModal } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    name: "MaterialDonationStatusConfirmationModal",
    components: {
        BRow,
        BCol,
        BModal,
    },
    data() {
        return {
            active: false,
            formData: {
                startDate: "",
                endDate: "",
            },
        }
    },
    methods: {
        show() {
            this.active = true
            this.setAttributesDefaultValues()
        },
        hide() {
            this.active = false
            this.setAttributesDefaultValues()
        },

        setAttributesDefaultValues() {
            const today = new Date()
            const dateString = today.toISOString().split("T")[0]

            this.formData.startDate = dateString
            this.formData.endDate = dateString
        },
        async handleSubmit() {
            try {
                const response = await Api.axios.get(
                    `/api/v1/material-donations/-actions/export-print-receipt-list?` +
                        `startDate=${this.formData.startDate}&endDate=${this.formData.endDate}`,
                    { responseType: "blob" } // importante
                )

                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                )
                const link = document.createElement("a")
                link.href = url
                link.setAttribute(
                    "download",
                    "relatorio-formulários-coleta-doacoes-materiais.pdf"
                )
                document.body.appendChild(link)
                link.click()

                this.$toast(toastMessages.getSuccessMessage())
                this.hide()
            } catch (e) {
                this.$formulate.handle(e, "CollectionListForm")
                this.$toast(toastMessages.getUnexpectedError())
                this.$bvModal.hide("material-donation-collection-modal")
            }
        },
    },
}
</script>

<style lang="scss">
.text-white {
    color: #ffffff;
}
</style>
