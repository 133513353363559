<template>
    <div>
        <create-address
            v-if="resourceData"
            id="create-addresses-modal"
            :resourceType="resourceType"
            :resourceData="resourceData"
            :addressEdit="addressEdit"
            :addressClassificationsOptions="addressClassificationsOptions"
            v-on="$listeners"
            @hide="addressEdit = null"
        >
        </create-address>

        <b-card>
            <datatable-header
                :title="$tc('common.address.description', 2)"
                :genericSearchEnabled="true"
                :searchTermPlaceHolder="$t('common.search')"
                @filterUpdated="onFilterUpdate"
            >
                <template #buttons>
                    <b-button
                        class="ml-2"
                        variant="primary"
                        style="background-color: #519bd6"
                        @click="
                            $root.$emit(
                                'bv::toggle::modal',
                                'create-addresses-modal'
                            )
                        "
                    >
                        {{
                            $t("common.crud.addNew", {
                                resource: $tc(
                                    "common.address.description",
                                    1
                                ).toLowerCase(),
                            }).toString()
                        }}
                    </b-button>
                </template>

                <template #advancedSearch="{ expanded, onFilterChange }">
                    <DatatableFilter
                        :availableDatatableFilters="availableDatatableFilters"
                        :expanded="expanded"
                        @filterUpdated="onFilterUpdate"
                        @onFilterChange="onFilterChange"
                    ></DatatableFilter>
                </template>
            </datatable-header>
            <datatable
                :columns="columns"
                :isLoading="isLoading"
                :paginationOptions="{
                    enabled: false,
                }"
                :rows="orderAddresses"
                :rtl="direction"
                :searchOptions="{
                    enabled: false,
                    externalQuery: searchTerm,
                }"
                :totalRows="dataTableOptions.pagination.totalRows"
                theme="my-theme"
                @on-page-change="handleChangePage"
                @onPageSizeChange="handlePageSizeChange"
            >
                <template #table-row="props">
                    <span v-if="props.column.field === 'isMainAddress'">
                        <b-badge
                            :class="
                                applyClass(
                                    props.row.isMainAddress
                                        ? props.row.isMainAddress
                                        : false
                                )
                            "
                        >
                            {{
                                props.row.isMainAddress
                                    ? $t("common.primary")
                                    : $t("common.secondary")
                            }}
                        </b-badge>
                    </span>

                    <span v-else-if="props.column.field === 'actions'">
                        <span class="d-inline">
                            <feather-icon
                                v-b-modal.create-addresses-modal
                                icon="Edit2Icon"
                                size="20"
                                style="cursor: pointer"
                                @click="addressEdit = props.row"
                            />
                        </span>
                        <span class="d-inline pl-1">
                            <feather-icon
                                icon="Trash2Icon"
                                size="20"
                                style="cursor: pointer"
                                @click="onDeleteClick(props.row)"
                            />
                        </span>
                    </span>

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </datatable>
        </b-card>
    </div>
</template>

<script>
import { BCard, BBadge, BButton } from "bootstrap-vue"
import store from "@/store/index"
import Datatable from "@/views/components/utilis-spa/datatable/Datatable.vue"
import DatatableFilter from "@/views/components/utilis-spa/datatable/DatatableFilter.vue"
import DatatableFilterUtils from "@/views/components/utilis-spa/datatable/datatableFilterUtil"
import Api from "@/services/api/index"
import _ from "lodash"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import DatatableHeader from "@/views/components/utilis-spa/datatable/DatatableHeader.vue"
import CreateAddress from "./AttachAddressModal.vue"

export default {
    components: {
        BButton,
        DatatableHeader,
        BCard,
        Datatable,
        DatatableFilter,
        CreateAddress,
        BBadge,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
        addressClassificationsOptions: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            addressEdit: null,
            dataTableOptions: {
                pagination: {
                    perPage: 5,
                    totalRows: 0,
                },
            },
            isLoading: true,
            dir: false,
            columns: [
                {
                    label: this.$t("common.type"),
                    field: "isMainAddress",
                },
                {
                    label: this.$t("common.address.type"),
                    field: "addressClassification.description",
                },
                {
                    label: this.$t("common.address.postalCode"),
                    field: "postalCode",
                },
                {
                    label: this.$tc("common.address.description", 1),
                    field: "street",
                },
                {
                    label: this.$t("common.address.number"),
                    field: "number",
                },
                {
                    label: this.$t("common.address.complement"),
                    field: "complement",
                },
                {
                    label: this.$t("common.address.reference"),
                    field: "reference",
                },
                {
                    label: this.$t("common.address.neighborhood"),
                    field: "neighborhood",
                },
                {
                    label: this.$t("common.address.city"),
                    field: "locality",
                },
                {
                    label: this.$t("common.address.state"),
                    field: "state",
                },
                {
                    label: this.$t("common.crud.actions"),
                    field: "actions",
                },
            ],
            searchTerm: "",
            availableDatatableFilters: {},
            apiFilter: null,
            showCreateComponent: false,
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },

        orderAddresses() {
            return _.orderBy(
                this.resourceData.addresses,
                ["isMainAddress"],
                "desc"
            )
        },

        applyClass() {
            const isMainAddressColor = {
                true: "badge-success",
                false: "badge-secondary",
            }

            return isMainAddress => isMainAddressColor[isMainAddress]
        },
    },
    created() {
        this.isLoading = false
    },
    methods: {
        formatDateTime(date) {
            if (date == null) return

            // eslint-disable-next-line consistent-return
            return this.$d(new Date(date), "dateTime")
        },
        // eslint-disable-next-line no-unused-vars
        handleChangePage(page) {},
        handlePageSizeChange(pageSize) {
            this.dataTableOptions.pagination.perPage = pageSize
        },
        onFilterUpdate(updatedFilter) {
            this.apiFilter = DatatableFilterUtils.serializer.datatableFilterToJsonApi(
                updatedFilter
            )
        },

        async onDeleteClick(params) {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one(`${this.resourceType}`, this.resourceData.id)
                    .relationships("addresses")
                    .destroy([
                        {
                            type: "addresses",
                            id: params.id,
                        },
                    ])

                this.$toast(toastMessages.getSuccessMessage())
                this.$emit("deleteAddress", params)
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>
