<template>
    <div class="component">
        <b-card class="">
            <b-row>
                <b-col cols="12" md="4">
                    <b-row>
                        <b-col cols="6" md="3" class="mr-2">
                            <b-avatar
                                variant="info"
                                :text="getNameInitials"
                                size="10rem"
                            ></b-avatar>
                        </b-col>

                        <b-col class="mr-2" cols="6" md="8">
                            <b-row>
                                <h4
                                    v-if="personData.name"
                                    class="text-truncate d-block"
                                >
                                    {{ personData.name }}
                                </h4>
                            </b-row>

                            <b-row class="mt-3">
                                <b-col>
                                    <b-row>
                                        <p class="font-medium-2">
                                            {{
                                                $t("modules.people.profile") +
                                                " " +
                                                $t("common.status")
                                            }}
                                        </p>
                                    </b-row>

                                    <b-row class="">
                                        <b-badge
                                            v-if="personData.status"
                                            :variant="
                                                statusVariant(
                                                    personData.status
                                                        .description
                                                )
                                            "
                                            class="mr-1"
                                            style="height: 1.5rem"
                                        >
                                            {{ personData.status.description }}
                                        </b-badge>

                                        <p
                                            v-if="personData.id"
                                            class="text-truncate"
                                        >
                                            ID: {{ personData.id }}
                                        </p>
                                    </b-row>

                                    <b-row>
                                        <p v-if="personData.editor">
                                            {{
                                                $t(
                                                    "common.crud.lastUpdatedAtBy",
                                                    {
                                                        time: $d(
                                                            new Date(
                                                                personData.updatedAt
                                                            ),
                                                            "dateTime"
                                                        ),
                                                        user:
                                                            personData.editor
                                                                .name,
                                                    }
                                                )
                                            }}
                                        </p>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row class="mt-4">
                        <b-col>
                            <b-button
                                v-if="$can('edit people')"
                                class="mr-1"
                                variant="primary"
                                style="background-color: #519bd6"
                                @click="
                                    $emit(
                                        'openEditResourceTab',
                                        'people-edit-tab-form'
                                    )
                                "
                            >
                                {{
                                    $t("common.crud.editResource", {
                                        resource: $t(
                                            "modules.people.profile"
                                        ).toLowerCase(),
                                    })
                                }}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" md="4">
                    <formulateForm v-model="formData" name="headerForm">
                        <b-row class="d-block mb-1">
                            <div>
                                <feather-icon
                                    icon="StarIcon"
                                    size="18"
                                ></feather-icon>
                                <span class="ml-1"
                                    ><strong>{{
                                        $t("common.relationships")
                                    }}</strong></span
                                >
                            </div>
                            <div class="mt-1">
                                <formulateInput
                                    name="relationships"
                                    type="vue-select"
                                    multiple
                                    validation="optional"
                                    :options="
                                        relationshipsOptions.map((el) => ({
                                            value: el.id,
                                            label: el.description,
                                        }))
                                    "
                                    @input="handleRelationshipsUpdate"
                                    @update:input="
                                        formData.relationships = $event
                                    "
                                />
                            </div>
                        </b-row>

                        <b-row class="d-block">
                            <div>
                                <feather-icon
                                    icon="AwardIcon"
                                    size="18"
                                ></feather-icon>
                                <span class="ml-1"
                                    ><strong>{{
                                        $t("common.classifications")
                                    }}</strong></span
                                >
                            </div>
                            <div class="mt-1">
                                <formulateInput
                                    name="classifications"
                                    type="vue-select"
                                    multiple
                                    validation="optional"
                                    :options="
                                        classificationsOptions.map((el) => ({
                                            value: el.id,
                                            label: el.description,
                                        }))
                                    "
                                    @input="handleClassificationsUpdate"
                                    @update:input="
                                        formData.classifications = $event
                                    "
                                />
                            </div>
                        </b-row>
                    </formulateForm>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BRow,
    VBToggle,
} from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import _ from "lodash"

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BAvatar,
        BBadge,
        BButton,
    },
    directives: {
        "b-toggle": VBToggle,
    },
    props: {
        classificationsOptions: {
            required: true,
            type: Array,
        },
        relationshipsOptions: {
            required: true,
            type: Array,
        },
        personData: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            formData: {
                relationships: [],
                classifications: [],
            },
            collapseVisible: false,
            classifications: [],
            relationships: [],
            enableClassificationRequest: false,
            enableRelationshipRequest: false,
        }
    },
    computed: {
        getNameInitials() {
            const split = this.personData.name.split(" ", 2)
            if (split.length < 2) {
                return this.personData.name[0] + this.personData.name[1]
            }

            const first = split[0]
            const second = split[1]

            return first.charAt(0) + second.charAt(0)
        },

        statusVariant() {
            const statusColor = {
                Ativo: "light-success",
                Inativo: "light-danger",
            }

            return status => statusColor[status]
        },
    },
    created() {
        this.formData.classifications =
            (_.get(this.personData, "classifications") &&
                this.personData.classifications.map(el =>
                    el.id.toString()
                )) ||
            []

        this.formData.relationships =
            (_.get(this.personData, "relations") &&
                this.personData.relations.map(el => el.id.toString())) ||
            []
    },
    methods: {
        async handleClassificationsUpdate() {
            if (!this.enableClassificationRequest) {
                this.enableClassificationRequest = true
                return
            }
            try {
                const ids = this.formData.classifications.map(el => ({
                    id: el,
                }))
                await Api.one("people", this.personData.id)
                    .relationships("classifications")
                    .patch(ids)

                const values = this.classificationsOptions
                    .filter(el =>
                        this.formData.classifications.includes(el.id)
                    )
                    .map(el => el.description)

                this.$toast(
                    toastMessages.getSuccessSync(
                        "common.crud.successfulSyncronization",
                        "common.classifications",
                        values
                    )
                )
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        async handleRelationshipsUpdate() {
            if (!this.enableRelationshipRequest) {
                this.enableRelationshipRequest = true
                return
            }
            try {
                const ids = this.formData.relationships.map(el => ({
                    id: el,
                }))
                await Api.one("people", this.personData.id)
                    .relationships("relations")
                    .patch(ids)

                const values = this.relationshipsOptions
                    .filter(el => this.formData.relationships.includes(el.id))
                    .map(el => el.description)

                this.$toast(
                    toastMessages.getSuccessSync(
                        "common.crud.successfulSyncronization",
                        "common.relationships",
                        values
                    )
                )
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style scoped>
.remove-select-borders > div:first-of-type {
    border: none;
}
</style>
