<template>
    <b-modal
        v-bind="$attrs"
        ref="attach-address"
        centered
        :title="
            $tc('common.crud.attaching', 2) +
            ' ' +
            $tc('common.address.description', 1).toLowerCase()
        "
        hideFooter
        @show="setAttributesDefaultValues"
    >
        <FormulateForm
            v-slot="{ isLoading, hasErrors }"
            v-model="formData"
            name="addressForm"
            @submit="handleSubmit"
        >
            <b-overlay :show="loadingAddressesRepository" rounded="sm">
                <b-row>
                    <b-col cols="12">
                        <formulate-input
                            name="addressClassification"
                            type="select"
                            inputClass="form-control"
                            :label="$t('common.address.type')"
                            :placeholder="
                                $t('common.crud.select') +
                                ' ' +
                                $t(
                                    'common.address.addressClassification'
                                ).toLowerCase()
                            "
                            class=""
                            :options="
                                addressClassificationsOptions.map((el) => ({
                                    value: el.id,
                                    label: el.description,
                                }))
                            "
                        >
                        </formulate-input>
                    </b-col>
                </b-row>

                <div class="container pl-0 pr-0">
                    <b-row>
                        <b-col cols="12" md="6">
                            <FormulateInput
                                :label="$t('common.address.postalCode')"
                                :placeholder="$t('common.address.postalCode')"
                                name="postalCode"
                                type="text"
                                validation="required|max:8,length|min:8,length"
                                href="postalCode"
                                @validation="getAddress"
                            />
                        </b-col>

                        <b-col class="align-self-end pr-0 pl-0 mb-1">
                            <span>{{
                                $t("common.address.postalCodeQuestion")
                            }}</span>
                            <a
                                class="d-block under-blue-text float-right mr-1"
                                href="https://buscacepinter.correios.com.br/app/localidade_logradouro/index.php"
                                target="_blank"
                            >
                                {{ $t("common.crud.searchHere") }}
                            </a>
                        </b-col>
                    </b-row>
                </div>

                <b-row>
                    <b-col cols="12">
                        <FormulateInput
                            :label="$tc('common.address.description', 1)"
                            :placeholder="$tc('common.address.description', 1)"
                            name="description"
                            type="text"
                            validation="optional|max:250,length"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <FormulateInput
                            :label="$t('common.address.neighborhood')"
                            :placeholder="$t('common.address.neighborhood')"
                            name="neighborhood"
                            type="text"
                            validation="optional|max:100,length"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="4" md="4">
                        <FormulateInput
                            :label="$t('common.address.number')"
                            :placeholder="$t('common.address.number')"
                            name="number"
                            type="text"
                            validation="required|max:10,length"
                        />
                    </b-col>

                    <b-col cols="8" md="8">
                        <FormulateInput
                            :label="$t('common.address.complement')"
                            :placeholder="$t('common.address.complement')"
                            name="complement"
                            type="text"
                            validation="optional|max:100,length"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="4" md="4">
                        <FormulateInput
                            :label="$t('common.address.state')"
                            :placeholder="$t('common.address.state')"
                            name="state"
                            type="select"
                            :options="stateOptions"
                            validation="optional"
                        />
                    </b-col>
                    <b-col cols="8" md="8">
                        <FormulateInput
                            :label="$t('common.address.city')"
                            :placeholder="$t('common.address.city')"
                            name="city"
                            type="text"
                            validation="optional|max:250,length"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" md="12">
                        <FormulateInput
                            :label="$t('common.address.reference')"
                            :placeholder="$t('common.address.reference')"
                            name="reference"
                            type="text"
                            validation="optional|max:250,length"
                        />
                        <!--                    <FormulateInput-->
                        <!--                        :label="$t('common.address.country')"-->
                        <!--                        :placeholder="$t('common.address.country')"-->
                        <!--                        name="country"-->
                        <!--                        type="text"-->
                        <!--                        validation="optional|max:250,length"-->
                        <!--                    />-->
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <FormulateInput
                            name="isMainAddress"
                            type="checkbox"
                            :label="
                                $t('common.crud.isMainResource', {
                                    resource: $tc(
                                        'common.address.description',
                                        1
                                    ).toLowerCase(),
                                })
                            "
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="d-flex justify-content-center mt-1">
                        <FormulateErrors />
                    </b-col>
                </b-row>

                <b-row class="mt-2">
                    <b-col class="d-flex justify-content-center">
                        <FormulateInput
                            v-if="!addressEdit"
                            :disabled="hasErrors || isLoading"
                            :label="
                                isLoading
                                    ? $tc('common.crud.attaching', 1)
                                    : $tc('common.crud.attaching', 2)
                            "
                            inputClass="btn btn-primary"
                            type="submit"
                        />
                        <FormulateInput
                            v-else
                            :disabled="hasErrors || isLoading"
                            :label="
                                isLoading
                                    ? $t('common.crud.updating')
                                    : $t('common.crud.update')
                            "
                            inputClass="btn btn-primary"
                            type="submit"
                        />
                    </b-col>

                    <b-col class="d-flex justify-content-center">
                        <FormulateInput
                            :label="$t('common.crud.close')"
                            inputClass="btn btn-danger"
                            type="button"
                            @click="hideModal"
                        />
                    </b-col>
                </b-row>
            </b-overlay>
        </FormulateForm>
    </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BOverlay } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"
import _ from "lodash"

export default {
    components: {
        BModal,
        BRow,
        BCol,
        BOverlay,
    },
    props: {
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
        addressEdit: {
            required: false,
            type: Object,
            default: null,
        },
        addressClassificationsOptions: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            formData: {
                postalCode: "",
                isMainAddress: false,
                reference: "",
                number: "",
                complement: "",
                description: "",
                neighborhood: "",
                addressClassification: "",
            },
            loadingAddressesRepository: false,

            stateOptions: [
                "AC",
                "AL",
                "AP",
                "AM",
                "BA",
                "CE",
                "ES",
                "GO",
                "MA",
                "MT",
                "MS",
                "MG",
                "PA",
                "PB",
                "PR",
                "PE",
                "PI",
                "RJ",
                "RN",
                "RS",
                "RO",
                "RR",
                "SC",
                "SP",
                "SE",
                "TO",
                "DF",
            ],
        }
    },
    methods: {
        setAttributesDefaultValues() {
            if (this.addressEdit) {
                this.formData.postalCode = this.addressEdit.postalCode
                this.formData.addressClassification =
                    _.get(this.addressEdit, "addressClassification.id") || ""
                this.formData.isMainAddress = this.addressEdit.isMainAddress
                this.formData.reference = this.addressEdit.reference
                this.formData.number = this.addressEdit.number
                this.formData.complement = this.addressEdit.complement || ""
                this.formData.description = this.addressEdit.description || ""
                this.formData.street = this.addressEdit.street || ""
                this.formData.state = this.addressEdit.state || ""
                this.formData.neighborhood =
                    this.addressEdit.neighborhood || ""
            } else {
                this.formData.postalCode = ""
                this.formData.isMainAddress = false
                this.formData.reference = ""
                this.formData.addressClassification = ""
                this.formData.number = ""
                this.formData.complement = ""
                this.formData.description = ""
                this.formData.street = ""
                this.formData.state = ""
                this.formData.neighborhood = ""
            }
        },
        async getAddress(event) {
            if (event.hasErrors) return

            this.loadingAddressesRepository = true
            try {
                const response = await Api.findAll("addresses-repository", {
                    filter: {
                        postalCode: this.formData.postalCode,
                    },
                })

                this.formData.description = response.data[0].street
                this.formData.city = response.data[0].locality
                this.formData.state = response.data[0].state
                this.formData.neighborhood = response.data[0].neighborhood
            } finally {
                this.loadingAddressesRepository = false
            }
        },
        async handleSubmit() {
            let addressResponse = null
            let addressClassification = null
            let modelType = null
            if (this.resourceType === "people") modelType = "PERSON"
            else if (this.resourceType === "companies") modelType = "COMPANY"

            try {
                if (this.addressEdit) {
                    addressResponse = await Api.update("addresses", {
                        id: this.addressEdit.id.toString(),
                        postalCode: this.formData.postalCode,
                        isMainAddress: this.formData.isMainAddress,
                        reference: this.formData.reference,
                        number: this.formData.number,
                        complement: this.formData.complement,
                        description: this.formData.description,
                        modelId: parseFloat(this.resourceData.id),
                        modelType,
                        addressClassification: this.formData
                            .addressClassification
                            ? {
                                  type: "address-classifications",
                                  id: this.formData.addressClassification,
                              }
                            : null,
                    })
                } else {
                    addressResponse = await Api.create("addresses", {
                        postalCode: this.formData.postalCode,
                        isMainAddress: this.formData.isMainAddress,
                        reference: this.formData.reference,
                        number: this.formData.number,
                        complement: this.formData.complement,
                        description: this.formData.description,
                        modelId: parseFloat(this.resourceData.id),
                        modelType,
                        addressClassification: this.formData
                            .addressClassification
                            ? {
                                  type: "address-classifications",
                                  id: this.formData.addressClassification,
                              }
                            : null,
                    })
                }

                if (this.formData.addressClassification) {
                    addressClassification = {
                        id: this.formData.addressClassification,
                        description: this.addressClassificationsOptions.filter(
                            address =>
                                address.id ===
                                this.formData.addressClassification
                        )[0].description,
                    }
                }

                if (this.addressEdit)
                    this.$emit("deleteAddress", this.addressEdit)

                this.$emit("attachAddress", {
                    ...addressResponse.data,
                    addressClassification,
                })

                this.$toast(toastMessages.getSuccessMessage())

                this.hideModal()
            } catch (e) {
                this.$formulate.handle(e, "addressForm")
                this.$toast(toastMessages.getUnexpectedError())
            }
        },

        showModal() {
            this.setAttributesDefaultValues()
            this.$refs["attach-address"].show()
        },
        hideModal() {
            this.$formulate.resetValidation("addressForm")
            this.$refs["attach-address"].hide()
        },
    },
}
</script>

<style scoped></style>
