<template>
    <b-card class="pb-0 mb-0 pr-0 pl-0">
        <div v-if="!noHeader" class="d-block mb-2">
            <feather-icon icon="TrelloIcon" size="28" />
            <h4 class="d-inline">
                {{ $tc("modules.companies.description", 1) }}
            </h4>
            <feather-icon
                v-if="resourceData.company"
                icon="XSquareIcon"
                size="28"
                class="float-right"
                :stroke="'#519BD6'"
                style="cursor: pointer"
                @click="detachCompany"
            />
        </div>

        <b-row v-if="resourceData.company">
            <div class="d-inline-block pl-1">
                <feather-icon icon="BriefcaseIcon" size="18"></feather-icon>
                <strong>
                    <span class="ml-1">
                        {{ $tc("modules.companies.description", 1) }}
                    </span>
                </strong>
            </div>
            <div class="d-inline-block pl-3">
                <a
                    class="d-block under-blue-text ml-1"
                    @click="
                        $router.push({
                            name: 'view-companies',
                            params: { id: resourceData.company.companyId },
                        })
                    "
                    @click.middle="openNewTab"
                >
                    {{ resourceData.company.name }}
                </a>
                <span class="ml-1"></span>
            </div>
        </b-row>

        <b-row v-if="resourceData.company" class="ml-0 mt-2">
            <feather-icon icon="PhoneIcon" size="18"></feather-icon>
            <strong
                ><span class="ml-1">{{
                    $tc("common.phone.description", 1)
                }}</span></strong
            >
            <div
                v-for="(phone, index) in resourceData.company.phones"
                :key="`index-${index}`"
                class="w-100 mt-1"
                @mouseover="showControls(index, 'phones')"
            >
                <b-row>
                    <b-col cols="12" md="5">
                        <feather-icon
                            icon="StarIcon"
                            size="15"
                            class="custom-icon under-blue-text mr-1"
                            :fill="
                                phone.isMainPhoneNumber ? '#519BD6' : '#ffffff'
                            "
                        ></feather-icon>
                        <span>
                            {{ maskedPhoneNumber(phone.number) }}
                        </span>
                    </b-col>
                    <b-col cols="12" md="4">
                        <span v-if="phone.phoneClassification">
                            {{ phone.phoneClassification.description }}
                        </span>
                    </b-col>
                </b-row>
            </div>

            <b-col cols="12" md="12" class="pl-0 pr-0 mt-2">
                <feather-icon icon="AtSignIcon" size="18"></feather-icon>
                <strong
                    ><span class="ml-1">{{
                        $tc("common.email.description", 1)
                    }}</span></strong
                >
                <div
                    v-for="(email, index) in resourceData.company.emails"
                    :key="`index-${index}`"
                    class="mt-1"
                    @mouseover="showControls(index, 'emails')"
                >
                    <feather-icon
                        icon="StarIcon"
                        size="15"
                        class="custom-icon under-blue-text mr-1"
                        :fill="email.isMainEmailAddress ? '#519BD6' : '#ffffff'"
                    ></feather-icon>
                    <span>
                        {{ email.address }}
                    </span>
                </div>
            </b-col>
        </b-row>

        <b-row v-else class="ml-1 mr-1">
            <formulateForm
                v-model="formData"
                name="companyRelationshipForm"
                class="w-100"
            >
                <formulateInput
                    :label="''"
                    name="company"
                    :placeholder="$t('common.crud.select')"
                    type="vue-select-async"
                    validation="optional"
                    :apiData="{
                        resource: 'companies',
                        fields: { companies: 'name' },
                        fieldsMapping: {
                            label: 'name',
                            value: 'id',
                        },
                    }"
                    :options="companiesOptions"
                    @update:input="formData.company = $event"
                    @update:options="companiesOptions = $event"
                    @input="updateCompanyRelationship"
                />
            </formulateForm>
        </b-row>
    </b-card>
</template>

<script>
import { BRow, BCard, BCol } from "bootstrap-vue"
import Api from "@/services/api/index"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    name: "CompanyCard",
    components: {
        BRow,
        BCard,
        BCol,
    },
    props: {
        noHeader: {
            required: false,
            type: Boolean,
            default: false,
        },
        resourceData: {
            required: true,
            type: Object,
        },
        resourceType: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            selected: "",
            phoneClassificationsOptions: [],
            companiesOptions: [],
            formData: {
                company: null,
            },
        }
    },
    async mounted() {
        const phoneClassifications = await Api.findAll(
            "phone-classifications",
            {
                fields: { "phone-classifications": "description" },
            }
        )
        this.phoneClassificationsOptions = phoneClassifications.data
    },
    methods: {
        showControls(index, resource) {
            this.selected = { index, resource }
        },
        hideControls() {
            this.selected = ""
        },
        openNewTab() {
            const routeData = this.$router.resolve({
                name: "view-companies",
                params: { id: this.resourceData.company.companyId },
            })
            window.open(routeData.href, "_blank")
        },
        async updateCompanyRelationship() {
            if (!this.formData.company) return
            try {
                const request = this.formData.company
                    ? [{ id: this.formData.company }]
                    : []
                const response = await Api.one(
                    this.resourceType,
                    this.resourceData.id
                )
                    .relationships("companies")
                    .patch(request)

                this.$emit("updateCompany", response.data[0])

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
        async detachCompany() {
            const confirmDelete = await this.$bvModal.msgBoxConfirm(
                this.$t("common.crud.confirmDelete"),
                {
                    title: this.$t("common.crud.confirmPlease"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("common.crud.yes"),
                    cancelTitle: this.$t("common.crud.no"),
                    cancelVariant: "primary",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                }
            )
            if (!confirmDelete) return
            try {
                await Api.one(this.resourceType, this.resourceData.id)
                    .relationships("companies")
                    .patch([])
                this.$set(this.formData, "company", null)
                this.$emit("deleteCompany")

                this.$toast(toastMessages.getSuccessMessage())
            } catch (e) {
                this.$toast(toastMessages.getUnexpectedError())
            }
        },
    },
}
</script>

<style scoped>
.custom-icon {
    cursor: auto !important;
    text-decoration: underline;
    text-decoration-color: #519bd6;
    color: #519bd6;
    font-size: 1rem;
}
</style>
